/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactNode } from 'react';
import colors from './ci_theme.module.scss';

interface Props {
    children: ReactNode | ReactNode[];
}
interface CIThemeProps {
    ciPalette: {
        brand: {
            blue: string;
            gray: string;
            red: string;
            green: string;
        };
        common: {
            black: string;
            white: string;
            blue: string;
            darkBlue: string;
            blueHover: string;
            shadow: string;
            orange: string;
            light_orange: string;
            lighterOrange: string;
            light_blue: string;
            lightBlue: string;
            paleGreen: string;
        };
        button: {
            siteGray: string;
            siteBlue: string;
            hover: string;
            outline: string;
            disabled: string;
        };
        border: {
            inactive: string;
            lightGray: string;
            lightBlue: string;
        };
        lightGrey: {
            inactive: string;
            border: string;
            text: string;
        };
        lightGreen: {
            border: string;
            background: string;
        };
        lightBlue: {
            border: string;
            headerBorder: string;
            hoverButton: string;
        };
        background: {
            green: string;
            fullWhite: string;
            lightBlue: string;
            lightGreen: string;
            gray: string;
            tableHover: string;
            yellow: string;
            powderGray: string;
            yellowOutline: string;
            pink: string;
        };
        text: {
            fullBlack: string;
            lightBlack: string;
            helper: string;
        };
        error: {
            muiErrorRed: string;
            ciErrorRed: string;
            ciRedOutline: string;
        };
        success: {
            darkGreen: string;
        };
        outlined: {
            border: string;
        };
        hover: {
            userAccountButton: string;
        };
        patient: {
            activationPending: string;
            status: {
                Preconsent: string;
                DidNotConsent: string;
                Consented: string;
                Screened: string;
                ScreenFailed: string;
                Rescreened: string;
                Randomized: string;
                Enrolled: string;
                Discontinued: string;
                Completed: string;
                Reconsent: string;
            };
        };
        table: {
            evenRowBackground: string;
            oddRowBackground: string;
        };
        banner: {
            expiredText: string;
            expiredBackground: string;
            activationText: string;
            activationBackground: string;
        };
        buildStatus: {
            text: {
                build: string;
                live: string;
                pending: string;
                inReview: string;
                archived: string;
            };
            backgroundColor: {
                build: string;
                live: string;
                pending: string;
                inReview: string;
                archived: string;
            };
        };
    };
}

declare module '@mui/material/styles' {
    interface Theme extends CIThemeProps {}
    interface ThemeOptions extends CIThemeProps {}
}

const ciTheme = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'unset',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: colors.drawer_back_drop,
                },
                outlinedPrimary: {
                    border: `1px solid ${colors.ci_main_btn_border}`,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked:not(.Mui-disabled)': {
                        color: colors.ci_main_blue,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked:not(.Mui-disabled)': {
                        color: colors.ci_blue,
                    },
                    '&.MuiCheckbox-indeterminate': {
                        color: colors.ci_light_grey,
                    },
                    '& .PrivateSwitchBase-input': {
                        minWidth: 0,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: colors.ci_main_blue,
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: colors.ci_main_blue,
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: colors.ci_main_blue,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    color: colors.full_white,
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    padding: '24px',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 1100,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    fontFamily: 'Ubuntu, San Francisco, Helvetica Neue, Segoe UI, Tahoma, Roboto, sans-serif',
                },
                select: {
                    fontFamily: 'Ubuntu, San Francisco, Helvetica Neue, Segoe UI, Tahoma, Roboto, sans-serif',
                },
                selectLabel: {
                    fontFamily: 'Ubuntu, San Francisco, Helvetica Neue, Segoe UI, Tahoma, Roboto, sans-serif',
                },
                displayedRows: {
                    fontFamily: 'Ubuntu, San Francisco, Helvetica Neue, Segoe UI, Tahoma, Roboto, sans-serif',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                multiline: {
                    padding: '6px 0 7px',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    '&.MuiSelect-select': {
                        minHeight: '1.1876em',
                    },
                },
                underline: {
                    '&:after': {
                        borderBottomColor: colors.ci_main_blue,
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                underline: {
                    '&:after': {
                        borderBottomColor: colors.ci_main_blue,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.ci_main_blue,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused:not(.Mui-error)': {
                        color: colors.ci_main_blue,
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    '&.Mui-checked:not(.Mui-disabled)': {
                        color: colors.ci_main_blue,
                    },
                    '&.Mui-checked+.MuiSwitch-track': {
                        backgroundColor: colors.ci_main_blue,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    display: 'flex',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    padding: '3px',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused:not(.Mui-error)': {
                        color: colors.ci_main_blue,
                    },
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: colors.ci_main_blue,
                    },
                    '&.Mui-completed': {
                        color: colors.brand_green,
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#4791db',
            main: '#1976d2',
            dark: '#115293',
        },
        secondary: {
            light: '#e33371',
            main: '#dc004e',
            dark: '#9a0036',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
        },
        info: {
            light: '#64b5f6',
            main: '#2196f3',
            dark: '#1976d2',
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    ciPalette: {
        brand: {
            blue: colors.brand_blue,
            gray: colors.brand_grey,
            red: colors.brand_red,
            green: colors.brand_green,
        },
        common: {
            black: colors.full_black,
            white: colors.full_white,
            blue: colors.ci_blue,
            darkBlue: colors.ci_dark_blue,
            blueHover: colors.ci_blue_hover,
            shadow: colors.shadow_color,
            orange: '#C55300',
            light_orange: '#C5530010',
            lighterOrange: '#F2994A',
            light_blue: colors.ci_light_blue_background,
            lightBlue: colors.lt_blue,
            paleGreen: colors.ci_green_background,
        },
        button: {
            siteGray: colors.site_icon_button,
            siteBlue: colors.ci_site_blue_btn,
            hover: '#cfdfeb',
            outline: colors.ci_main_btn_border,
            disabled: colors.primary_disabled,
        },
        border: {
            inactive: colors.ci_inactive_progress,
            lightGray: colors.ci_light_gray_border_color,
            lightBlue: colors.mui_paper_back_drop,
        },
        lightGrey: {
            inactive: colors.light_grey_inactive,
            border: colors.light_grey_border,
            text: colors.ci_light_grey,
        },
        lightGreen: {
            border: colors.ci_light_green_border_color,
            background: '#f3faf2',
        },
        lightBlue: {
            border: colors.lt_blue,
            headerBorder: colors.ci_inactive_progress,
            hoverButton: '#294b94',
        },
        background: {
            green: colors.patient_activate_green,
            fullWhite: colors.full_white,
            lightBlue: colors.ci_light_blue,
            lightGreen: colors.ci_light_green_background,
            gray: colors.site_gray_bg,
            tableHover: colors.ci_table_hover_background,
            yellow: colors.ci_yellow_background,
            powderGray: colors.powder_gray,
            yellowOutline: colors.ci_yellow_outline,
            pink: '#F8E5FF',
        },
        text: {
            fullBlack: colors.full_black,
            lightBlack: colors.ci_light_black,
            helper: colors.mui_helpertext,
        },
        error: {
            muiErrorRed: colors.mui_error_red,
            ciErrorRed: colors.ci_red,
            ciRedOutline: colors.ci_red_outline,
        },
        success: {
            darkGreen: colors.success_text,
        },
        outlined: {
            border: colors['ci-outlined_border'],
        },
        hover: {
            userAccountButton: colors.user_account_button_hover,
        },
        patient: {
            activationPending: colors['patient-activation-pending'],
            status: {
                Preconsent: '#9f55c2',
                DidNotConsent: '#666666',
                Consented: '#4a5cf8',
                Screened: '#c55300',
                ScreenFailed: '#e60005',
                Rescreened: '#0c8083',
                Randomized: '#bd00ff',
                Enrolled: colors.ci_blue,
                Discontinued: colors.ci_text_color,
                Completed: '#00851f',
                Reconsent: '#e00094',
            },
        },
        table: {
            evenRowBackground: colors.ci_table_row_even_background,
            oddRowBackground: colors.ci_table_row_odd_background,
        },
        banner: {
            expiredText: '#F44336',
            expiredBackground: '#FEECEB',
            activationText: '#00851F',
            activationBackground: '#E5F3E9',
        },
        buildStatus: {
            text: {
                build: colors.ci_light_grey,
                live: colors.success_text,
                pending: colors.ci_blue,
                inReview: colors.ci_blue,
                archived: colors.ci_light_grey,
            },
            backgroundColor: {
                build: colors.light_grey_border,
                live: '#f3faf2',
                pending: '#247cb81a',
                inReview: '#247cb81a',
                archived: colors.ci_light_grey,
            },
        },
    },
});

export const CITheme = ({ children }: Props) => {
    return <ThemeProvider theme={ciTheme}>{children}</ThemeProvider>;
};
