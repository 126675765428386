import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AuthActions } from 'common/auth/auth_context';
import { DEFAULT_PATH } from 'module/auth/login/login.utils';

/**
 * Watches localstorage keys to sync states between browser tabs e.g. auth states
 */
export function useBrowserTabSync() {
    const dispatch = useDispatch();

    useEffect(() => {
        const authListener = (event: StorageEvent) => {
            if (event.key === 'activeUser' && !event.newValue) {
                dispatch(AuthActions.logoutRequest());
            }
            if (event.key === 'activeUser' && event.newValue && event.newValue !== event.oldValue) {
                sessionStorage.removeItem('activeStudySite');
                window.location.pathname = DEFAULT_PATH;
            }
            if (event.key === 'switchingUser') {
                dispatch(AuthActions.handleSwitchUser(event.newValue === 'true'));
            }
        };

        window.addEventListener('storage', authListener);

        return () => window.removeEventListener('storage', authListener);
    }, []);
}
