import {
    DeviceLabelTriggerStatus,
    useDeviceLabelTriggerStatus,
    useMeetingManager,
    UserActivityProvider,
    useRosterState,
    VideoTileGrid,
} from 'amazon-chime-sdk-component-library-react';
import { filter, find, values } from 'lodash';
import { useEffect, useState } from 'react';
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect';
import { useAppState } from '../../providers/AppStateProvider';
import { useNavigation } from '../../providers/NavigationProvider';
import { VideoTileGridProvider } from '../../providers/VideoTileGridProvider';
import '../../style.scss';
import { Layout } from '../../types';
import DynamicMeetingControls from '../DynamicMeetingControls';
import MeetingControls from '../MeetingControls';
import MeetingDetails from '../MeetingDetails';
import NavigationControl from '../Navigation/NavigationControl';
import { StyledContent, StyledLayout } from './Styled';

const MeetingRoom = () => {
    useMeetingEndRedirect();
    const { showNavbar, showRoster } = useNavigation();
    const { layout } = useAppState();
    const { roster } = useRosterState();
    const attendees = values(roster);
    const permission = useDeviceLabelTriggerStatus();
    const meetingManager = useMeetingManager();

    const [allowedDevices, setAllowedDevices] = useState(false);
    const [checkedDevices, setCheckedDevices] = useState(false);

    useEffect((): void => {
        const checkDeviceAccess = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            if (filter(devices, 'label').length > 0) {
                setAllowedDevices(true);
            }
            setCheckedDevices(true);
        };

        checkDeviceAccess();
    }, []);

    // By default, user has to select an item from the video/audio devices; this effect goes through the list of
    // devices that are allowed and sets the first proper match, so once user clicks in the device icons,
    // it will activate the default device
    useEffect((): void => {
        async function setDefaultDevices(): Promise<void> {
            if (checkedDevices && (permission === DeviceLabelTriggerStatus.GRANTED || allowedDevices)) {
                const devices = await navigator.mediaDevices.enumerateDevices();
                if (!meetingManager.selectedVideoInputDevice) {
                    await meetingManager.selectVideoInputDevice(find(devices, ['kind', 'videoinput'])?.deviceId || '');
                }
                if (!meetingManager.selectedAudioInputDevice) {
                    await meetingManager.startAudioInputDevice(find(devices, ['kind', 'audioinput'])?.deviceId || '');
                }
                if (!meetingManager.selectedAudioOutputDevice) {
                    await meetingManager.startAudioOutputDevice(find(devices, ['kind', 'audiooutput'])?.deviceId || '');
                }
            }
        }

        setDefaultDevices();
    }, [
        checkedDevices,
        permission,
        meetingManager.selectedVideoInputDevice,
        meetingManager.selectedAudioInputDevice,
        meetingManager.selectedAudioOutputDevice,
    ]);

    return (
        <UserActivityProvider>
            <VideoTileGridProvider>
                <StyledLayout showNav={showNavbar} showRoster={showRoster}>
                    <StyledContent>
                        <VideoTileGrid
                            layout={layout === Layout.Gallery ? 'standard' : 'featured'}
                            className="videos"
                            noRemoteVideoView={attendees.length === 1 && <MeetingDetails />}
                        />
                        {permission === DeviceLabelTriggerStatus.GRANTED || allowedDevices ? (
                            <MeetingControls />
                        ) : (
                            <DynamicMeetingControls />
                        )}
                    </StyledContent>
                    <NavigationControl />
                </StyledLayout>
            </VideoTileGridProvider>
        </UserActivityProvider>
    );
};

export default MeetingRoom;
