import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { OfflineSyncPlugin } from 'common/native-app-support/ci-native-plugins/CIPlugins';

export class OfflineManager {
    private offlineModeStatus: 'activated' | 'deactivated' = 'deactivated';
    private isOffline = false;

    private static __instance: OfflineManager;

    private constructor() {}

    public static getInstance(): OfflineManager {
        if (!OfflineManager.__instance) {
            OfflineManager.__instance = new OfflineManager();
        }

        return OfflineManager.__instance;
    }

    public async ready(): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            this.updateOfflineMode();
            const status = await Network.getStatus();
            await this.updateConnectionStatus(status);
        }
    }

    async updateConnectionStatus(status: ConnectionStatus): Promise<void> {
        this.isOffline = !status.connected;
    }

    isPhoneOffline(): boolean {
        return this.isOffline;
    }

    isOfflineModeActive(): boolean {
        return this.offlineModeStatus === 'activated';
    }

    async updateOfflineMode(): Promise<void> {
        const { studyId, siteId } = this.getActiveStudySite();

        const siteStudyOfflineCapable = await OfflineSyncPlugin.isSiteStudyOfflineCapable({
            siteId,
            studyId,
        });
        let isSiteStudyOfflineCapable = siteStudyOfflineCapable.isCapable;

        if (isSiteStudyOfflineCapable) {
            this.offlineModeStatus = 'activated';
        } else {
            this.offlineModeStatus = 'deactivated';
        }
    }

    private getActiveStudySite(): { studyId: string; siteId: string } {
        const activeStudySite = JSON.parse(sessionStorage.getItem('activeStudySite'));
        return { studyId: activeStudySite?.activeStudy?.studyId, siteId: activeStudySite?.activeSite?.siteId };
    }
}
