import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { capitalize } from 'lodash';
import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useSafeState } from '../../hooks/use-safe-set-state';
import './ci_pagestatus.scss';

export interface PageStatus {
    error: (message: string, duration?: number, callBack?: Function, fieldname?: string, icon?: ReactNode) => string;
    success: (message: string, duration?: number, callBack?: Function, icon?: ReactNode, showClose?: boolean) => string;
    info: (
        message: string,
        duration?: number,
        callBack?: Function,
        icon?: ReactNode,
        backgroundColor?: string
    ) => string;
    warn: (message: string, duration?: number, callBack?: Function, icon?: ReactNode, showClose?: boolean) => string;
    notify: (message: ReactNode, duration?: number, callBack?: Function, icon?: ReactNode) => string;
    dismiss: () => void;
    getStatus: () => SnackBarProps;
}

export class CIPageStatus {
    private static __actions: PageStatus = null;
    public static getActions(): PageStatus {
        return CIPageStatus.__actions;
    }
    public static setActions(_actions: PageStatus): void {
        CIPageStatus.__actions = _actions;
    }

    public static error(
        message: string,
        duration?: number,
        callBack?: Function,
        fieldname?: string,
        icon?: ReactNode
    ): string {
        return CIPageStatus.__actions?.error(message, duration, callBack, fieldname, icon);
    }
    public static success(
        message: string,
        duration?: number,
        callBack?: Function,
        icon?: ReactNode,
        showClose?: boolean
    ): string {
        return CIPageStatus.__actions?.success(message, duration, callBack, icon, showClose);
    }
    public static info(
        message: string,
        duration?: number,
        callBack?: Function,
        icon?: ReactNode,
        backgroundColor?: string
    ): string {
        return CIPageStatus.__actions?.info(message, duration, callBack, icon, backgroundColor);
    }
    public static warn(
        message: string,
        duration?: number,
        callBack?: Function,
        icon?: ReactNode,
        showClose?: boolean
    ): string {
        return CIPageStatus.__actions?.warn(message, duration, callBack, icon, showClose);
    }
    public static notify(message: ReactNode, duration?: number, callBack?: Function, icon?: ReactNode): string {
        return CIPageStatus.__actions?.notify(message, duration, callBack, icon);
    }

    public static getStatus(): SnackBarProps {
        return CIPageStatus.__actions?.getStatus();
    }

    public static dismiss(): void {
        CIPageStatus.__actions?.dismiss();
    }
}

export interface SnackBarProps {
    id: string;
    fieldname?: string;
    message: ReactNode;
    severity?: 'success' | 'info' | 'warning' | 'error';
    duration?: number;
    callBack?: Function;
    icon?: ReactNode;
    backgroundColor?: string;
    showClose?: boolean;
}

export const CISnackBar = (): ReactElement => {
    const [status, setStatus] = useSafeState<SnackBarProps>(null);

    const error = useCallback(
        (message, duration, callBack, fieldname, icon): string => {
            const id = uuid();
            setStatus({ id, message, severity: 'error', duration, callBack, fieldname, icon });
            return id;
        },
        [setStatus]
    );
    const success = useCallback(
        (message, duration, callBack, icon, showClose): string => {
            const id = uuid();
            setStatus({ id, message, severity: 'success', duration, callBack, icon, showClose });
            return id;
        },
        [setStatus]
    );
    const info = useCallback(
        (message, duration, callBack, icon, backgroundColor): string => {
            const id = uuid();
            setStatus({ id, message, severity: 'info', duration, callBack, icon, backgroundColor });
            return id;
        },
        [setStatus]
    );
    const warn = useCallback(
        (message, duration, callBack, icon, showClose): string => {
            const id = uuid();
            setStatus({ id, message, severity: 'warning', duration, callBack, icon, showClose });
            return id;
        },
        [setStatus]
    );
    const notify = useCallback(
        (message, duration, callBack, icon): string => {
            const id = uuid();
            setStatus({ id, message, severity: 'success', duration, callBack, icon });
            return id;
        },
        [setStatus]
    );
    const dismiss = useCallback((): void => {
        if (status?.callBack) {
            status.callBack();
        }
        setStatus(null);
    }, [setStatus, status?.callBack]);

    // Gets the current status
    const getStatus = useCallback(() => status, [status]);

    useEffect((): void => {
        CIPageStatus.setActions({ error, success, info, warn, dismiss, notify, getStatus });
    }, [error, success, info, warn, dismiss, notify, getStatus]);

    const autoHideDuration = status?.severity === 'success' && status?.duration === undefined ? 5000 : status?.duration;

    return (
        <>
            {status && (
                <Snackbar
                    open={!!status.message}
                    autoHideDuration={autoHideDuration}
                    onClose={dismiss}
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                    transitionDuration={{ enter: 0 }} // TODO: react rendering some pages twice, so it looks like it stutters on transition.
                    ClickAwayListenerProps={{ mouseEvent: false }} // This prevents closing the snackbar by clicking outside of it.
                >
                    <MuiAlert
                        style={{ backgroundColor: status.backgroundColor }}
                        id={`page${capitalize(status.severity)}`}
                        elevation={6}
                        variant="filled"
                        severity={status.severity}
                        onClose={status?.duration && !status?.showClose ? null : dismiss}
                        icon={status?.icon}
                    >
                        {status.fieldname ? `${status.fieldname} - ${status.message}` : status.message}
                    </MuiAlert>
                </Snackbar>
            )}
        </>
    );
};

export const withPageStatus = (WrappedComponent): Function => {
    return (props): ReactElement => <WrappedComponent {...props} pageStatus={CIPageStatus} />;
};
