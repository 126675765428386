import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { ReportsUrlParams } from 'module/mydata/types/types';
import { lazy } from 'react';

const ManageCustomReports = lazy(() => import('./manage/manage_reports'));
const StudySiteData = lazy(() => import('./study_site_data'));
const StudyData = lazy(() => import('./study_data'));
const StudyDataExport = lazy(() => import('./manage/study_data_export'));

export let routes = [
    {
        component: ManageCustomReports,
        path: `/studyreports/:${ReportsUrlParams.studyId}/reports/manage/custom`,
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        permissions: ['GetCustomReportsAdminAccessToken'],
    },
    {
        component: StudyData,
        path: `/studyreports/:${ReportsUrlParams.studyId}/reports/:${ReportsUrlParams.dashboardId}?`,
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.ClinicalTrialLead,
            RoleVariantEnum.AdminReadOnly,
        ],
        permissions: ['DataManagement_Study_Export'],
    },
    {
        component: StudySiteData,
        path: `/reports/:${ReportsUrlParams.dashboardId}?`,
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        permissions: ['Report_Standard_Read'],
        studySiteRequired: true,
    },
    {
        component: StudyDataExport,
        path: '/',
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.ClinicalTrialLead,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.AdminReadOnly,
        ],
    },
];
