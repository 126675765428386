import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

export let routes = [
    {
        component: lazy(() => import('module/administration/AdministrationPage')),
        permissions: [
            'SubscriberPP_Create',
            'SubscriberPP_Edit',
            'SubscriberPP_Delete',
            'ApplicationAudit_Read',
            'SubscriberTranslation_Create',
        ],
        role: [
            BaseRoles.UserId,
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SystemAdminstrator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/administration/:tabName',
    },
    {
        component: lazy(() => import('module/administration/policy/ManagePrivacyPolicyPage')),
        permissions: [
            'SubscriberPP_Create',
            'SubscriberPP_Edit',
            'SubscriberPP_Delete',
            'ApplicationAudit_Read',
            'SubscriberTranslation_Create',
        ],
        role: [
            BaseRoles.UserId,
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SystemAdminstrator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/administration/:tabName/manageprivacy/:id',
    },
    {
        component: lazy(() => import('module/administration/policy/LanguagesPage')),
        permissions: [
            'SubscriberPP_Create',
            'SubscriberPP_Edit',
            'SubscriberPP_Delete',
            'ApplicationAudit_Read',
            'SubscriberTranslation_Create',
        ],
        role: [
            BaseRoles.UserId,
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SystemAdminstrator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/administration/:tabName/manageprivacy/:id/version/:majorVersion',
    },
    {
        component: lazy(() => import('module/administration/apptranslations/TranslationsListPage')),
        permissions: ['SubscriberTranslation_Create'],
        role: [
            BaseRoles.UserId,
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/administration/apptranslations/:version/versionTranslations',
    },
];
