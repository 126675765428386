import {
    Roster,
    RosterAttendee,
    RosterAttendeeType,
    RosterGroup,
    RosterHeader,
    useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { filter, includes, map, toLower, trim, values } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '../providers/NavigationProvider';
import { styled } from '@mui/material';

const MeetingRoster = () => {
    const { t } = useTranslation('global');
    const { roster } = useRosterState();
    const [filterQuery, setFilter] = useState('');
    const { closeRoster } = useNavigation();

    let attendees = values(roster);

    if (filterQuery) {
        attendees = filter(attendees, (attendee: RosterAttendeeType) =>
            includes(toLower(attendee?.name), toLower(trim(filterQuery)))
        );
    }

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };

    const attendeeItems = map(attendees, (attendee: RosterAttendeeType) => {
        const { chimeAttendeeId } = attendee || {};
        return <RosterAttendee key={chimeAttendeeId} attendeeId={chimeAttendeeId} />;
    });

    return (
        <RosterContainer>
            <Roster className="roster">
                <RosterHeader
                    searchValue={filterQuery}
                    onSearch={handleSearch}
                    onClose={closeRoster}
                    title={t('telehealth.roster_header')}
                    badge={attendees.length}
                />
                <RosterGroup>{attendeeItems}</RosterGroup>
            </Roster>
        </RosterContainer>
    );
};

export default MeetingRoster;

const RosterContainer = styled('div')({
    '& .ch-buttons': {
        '.rtl &': {
            position: 'absolute',
            left: 0,
        },
    },
    '& .ch-title': {
        '.rtl &': {
            marginLeft: '0.5rem',
        },
    },
});
