import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

export const routes = [
    {
        component: lazy(() => import('module/studysitealerts/SiteAlertsPage')),
        permissions: [],
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/sitealerts/:studyId/:alertId?',
    },
    {
        component: lazy(() => import('module/studysitealerts-legacy/studysitealerts.page')),
        permissions: [],
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/alerts/:studyId/:alertId?',
    },
];
