import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { findKey } from 'lodash';
import { CognitoAuthError } from '../auth/auth_context';
import { CILogger } from '../log/log.provider';

export enum GraphQLErrorCode {
    VERSION_CONFLICT = 'graphql.VersionConflict',
    SYSTEM_ERROR = 'graphql.systemerror',
    INVALID_INPUT = 'graphql.InvalidInput',
}

export enum ErrorSource {
    APPSYNC,
    COGNITO,
    REST,
    UI,
    NATIVE,
}

export interface RestError {
    code: string;
    message: string;
    additionalInfo?: string;
}

export interface CIGraphQLError {
    code: string;
    message: string;
    additionalInfo?: { [key: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
    fieldName?: string;
}

export interface NativeError {
    code: string;
    message: string;
}

const logger: CILogger = CILogger.getInstance();

export class CIError {
    public errorSource: ErrorSource;
    public sourceError: CIGraphQLError;
    public message: string;
    public translationKey: string;
    public trigger: string;
    public fieldName?: string;

    public constructor(message, key, source = ErrorSource.UI, err) {
        this.errorSource = source;
        this.sourceError = err;
        this.message = message;
        this.translationKey = key;
        this.fieldName = err?.fieldName || null;
    }

    public static fromCognitoError(err: CognitoAuthError): CIError {
        return new CIError(err.message, 'cognito.' + err.code, ErrorSource.COGNITO, err);
    }

    public static fromGraphQLError(err: GraphQLError | ApolloError): CIError {
        if (err instanceof ApolloError) {
            if (err.networkError) {
                const errorCode = err.networkError['statusCode'] || 500; // https://www.apollographql.com/docs/link/links/http/#errors
                return new CIError(err.message, `network.${errorCode}error`, ErrorSource.APPSYNC, err);
            }

            // parse message field as it should contain a custom JSON from Lambda
            // as per https://clinicalink.atlassian.net/wiki/spaces/OP/pages/807665701/GraphQL+Information#GraphQLInformation-ReportingErrors
            let beError: CIGraphQLError;
            try {
                // Wrap the parse here as we can get into cases where the BE logic hasn't fired and we get non-standard formats back
                beError = JSON.parse(err.message);
            } catch (e) {
                logger.warn(
                    'Failed to parse message for CI backend error (JSON parse)',
                    'Original error:',
                    err.message
                );
                return new CIError(err.message, 'graphql.systemerror', ErrorSource.APPSYNC, err);
            }

            const { message, code, additionalInfo } = beError;
            let errorCode = code || 'systemerror'; // default

            if (code === 'InvalidInput' && additionalInfo) {
                if (additionalInfo.version) {
                    errorCode = 'update_version_conflict';
                    beError.code = errorCode;
                }
                // {"code":"InvalidInput","message":"Field 'username' is not unique","additionalInfo":{"username":"not_unique"}}
                const notUniqueField = findKey(additionalInfo, (value) => value === 'not_unique');
                if (notUniqueField) {
                    errorCode = `field_not_unique`;
                    beError.code = errorCode;
                    beError.fieldName = notUniqueField;
                }
            }
            return new CIError(message, `graphql.${errorCode}`, ErrorSource.APPSYNC, beError || err);
        } else {
            return new CIError(err.message, 'graphql.systemerror', ErrorSource.APPSYNC, err);
        }
    }

    public static fromRestError(err: RestError): CIError {
        if (!err.code) {
            err.code = 'system_error';
        }
        return new CIError(err.message, 'restapi.' + err.code, ErrorSource.REST, err);
    }

    public static fromNativeError(err: NativeError): CIError {
        return new CIError(err.message, 'native.' + err.code, ErrorSource.NATIVE, err);
    }
}
