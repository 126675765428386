import {
    AudioInputControl,
    AudioOutputControl,
    ControlBar,
    ControlBarButton,
    DeviceLabels,
    DeviceLabelTriggerStatus,
    Dots,
    useDeviceLabelTriggerStatus,
    useUserActivityState,
    VideoInputControl,
} from 'amazon-chime-sdk-component-library-react';
import DevicePermissionControl from 'common/telehealth/containers/DevicePermissionControl/DevicePermissionControl';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '../../providers/NavigationProvider';
import EndMeetingControl from '../EndMeetingControl';
import { StyledControls } from './Styled';

const DynamicMeetingControls = () => {
    const { t } = useTranslation('global');
    const { toggleNavbar, closeRoster, showRoster } = useNavigation();
    const { isUserActive } = useUserActivityState();
    const permission = useDeviceLabelTriggerStatus();

    const handleToggle = () => {
        if (showRoster) {
            closeRoster();
        }

        toggleNavbar();
    };

    return (
        <StyledControls className="controls" active={!!isUserActive}>
            <ControlBar className="controls-menu" layout="undocked-horizontal" showLabels>
                <ControlBarButton
                    className="mobile-toggle"
                    icon={<Dots />}
                    onClick={handleToggle}
                    label={t('telehealth.controls.menu')}
                />
                {permission === DeviceLabelTriggerStatus.GRANTED ? (
                    <>
                        <AudioInputControl
                            muteLabel={t('telehealth.controls.mute')}
                            unmuteLabel={t('telehealth.controls.unmute')}
                        />
                        <VideoInputControl />
                        <AudioOutputControl label={t('telehealth.controls.speaker')} />
                    </>
                ) : (
                    <DevicePermissionControl deviceLabels={DeviceLabels.AudioAndVideo} />
                )}

                <EndMeetingControl />
            </ControlBar>
        </StyledControls>
    );
};

export default DynamicMeetingControls;
