import { gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { CIError } from 'common/error/error_util';
import client from 'common/graphql/client';
import { Component, ReactElement } from 'react';
import StudyDeleted from '../../module/studydeleted/studydeleted';

const getStudyQuery = gql`
    query GetStudy($pk: String!) {
        getStudy(pk: $pk) {
            isDeleted
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class StudyRoute extends Component<any, any> {
    public constructor(props) {
        super(props);
        this.state = {
            isStudyAvailable: false,
            loading: true,
        };
    }
    public componentDidMount(): void {
        client
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .query<any>({
                query: gql`
                    ${getStudyQuery}
                `,
                variables: {
                    pk: this.props.studyId,
                },
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((response): any => {
                this.setState({ isStudyAvailable: !response.data.getStudy.isDeleted, loading: false });
            })
            .catch((err) => {
                throw CIError.fromGraphQLError(err);
            });
    }

    public render(): ReactElement {
        const { component: Component } = this.props;
        return (
            <>
                {this.state.loading ? (
                    <div className="loading-overlay__indicator-container">
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <>{this.state.isStudyAvailable ? <Component {...this.props} /> : <StudyDeleted />}</>
                )}
            </>
        );
    }
}
