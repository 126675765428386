import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useIsMounted } from './use-is-mounted.hook';

/**
 * Returns a function that would execute `setState` only if the component is mounted
 * @param setState setState from useState
 * @returns Function that would execute state updates only if the component is mounted
 */
export const useSafeSetState: <StateType>(
    setState: Dispatch<SetStateAction<StateType>>
) => (argToSetState: SetStateAction<StateType>) => void = (setState) => {
    const isMounted = useIsMounted();
    return useCallback((argToSetState) => (isMounted() ? setState(argToSetState) : void 0), [isMounted, setState]);
};

/**
 * Returns an array where the first variable is the current state, the second is the safeSetState
 * @param initialState the initial State
 * @returns [current state, setSafeState]
 */
export const useSafeState = <T>(initialState?: T): [T, (argToSetState: SetStateAction<T>) => void] => {
    const [state, setState] = useState<T>(initialState);
    const setSafeState = useSafeSetState(setState);
    return [state, setSafeState];
};
