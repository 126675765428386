import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/studydetails/study_details.page')),
        permissions: ['Study_Read', 'Study_Create', 'Study_Edit', 'Study_Delete'],
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.AdminReadOnly,
        ],
    },
    {
        component: lazy(() => import('module/studydetails/econsent/econsent.page')),
        permissions: ['Study_Read', 'Study_Create', 'Study_Edit', 'Study_Delete'],
        path: '/studydetails/:studyId/econsent',
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.AdminReadOnly,
        ],
    },
    {
        component: lazy(() => import('module/studydetails/study_details.page')),
        permissions: ['Study_Read', 'Study_Create', 'Study_Edit', 'Study_Delete'],
        path: '/studydetails/:studyId/:studyDetails?',
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.AdminReadOnly,
        ],
    },
];
