import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { getCookie, OrionCookies, setCookie } from 'common/utils/cookies';
import { jwtDecode } from 'jwt-decode';
import { keys, map, replace } from 'lodash';
import { SsoSessionKeys } from './sso.types';

const SSO_OKTA_BASE = process.env.REACT_APP_SSO_OKTA;
const CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_USERPOOL_CLIENTID;

export const SSO_OKTA = `${SSO_OKTA_BASE}/oauth2/authorize?identity_provider=Okta&redirect_uri=${document.location.protocol}//${document.location.host}/sso/okta&response_type=code&client_id=${CLIENT_ID}&scope=email%20profile`;
export const SSO_OKTA_TOKENS_FROM_CODE = `${SSO_OKTA_BASE}/oauth2/token`;
export const SSO_OKTA_REVOKE = `${SSO_OKTA_BASE}/oauth1/v1/revoke`;
export const SSO_OKTA_SLO = `${SSO_OKTA_BASE}/logout?client_id=${CLIENT_ID}&logout_uri=${document.location.protocol}//${document.location.host}/sso/okta/logout`;
export const SSO_OKTA_SLO_RELOGIN = `${SSO_OKTA_BASE}/logout?client_id=${CLIENT_ID}&logout_uri=${document.location.protocol}//${document.location.host}/sso/okta/relogin`;

const getParamsForTokensFromCode = (code: string, clientId: string, codeVerifier?: string): string => {
    const tokenBodyParts = {
        code,
        grant_type: 'authorization_code',
        scope: 'openid profile offline_access',
        client_id: clientId,
        redirect_uri: `${document.location.protocol}//${document.location.host}/sso/okta`,
        code_verifier: undefined,
    };
    if (codeVerifier) {
        tokenBodyParts.code_verifier = codeVerifier;
    }
    return map(keys(tokenBodyParts), (key) => `${key}=${encodeURIComponent(tokenBodyParts[key])}`).join('&');
};
export const getAccessTokensFromCode = async (
    code: string,
    oktaIssuer?: string, // need default value for now to make sure old code/workflow doesn't break
    oktaAppClientId?: string, // need default value for now to make sure old code/workflow doesn't break
    codeVerifier?: string
): Promise<SsoSessionKeys> => {
    const issuer = oktaIssuer || getCookie(OrionCookies.oktaIssuer) || SSO_OKTA_BASE;
    const clientId = oktaAppClientId || getCookie(OrionCookies.oktaClientId) || CLIENT_ID;

    const getOktaTokensFromCodeUrl = `${issuer}/oauth2/v1/token`;

    try {
        const response = await axios.post(
            getOktaTokensFromCodeUrl,
            getParamsForTokensFromCode(code, clientId, codeVerifier),
            {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'accept': 'application/json',
                    'cache-control': 'no-cache',
                },
            }
        );
        const { id_token: idToken, access_token: accessToken, refresh_token: refreshToken, expiry } = response.data;
        return {
            idToken,
            accessToken,
            refreshToken,
            expiry,
        };
    } catch (error) {
        throw error;
    }
};

const getParamsForTokensFromRefreshToken = (refreshToken: string, clientId: string): string => {
    const tokenBodyParts = {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        scope: 'openid profile offline_access',
        client_id: clientId,
        redirect_uri: `${document.location.protocol}//${document.location.host}/sso/okta`,
    };
    return map(keys(tokenBodyParts), (key) => `${key}=${encodeURIComponent(tokenBodyParts[key])}`).join('&');
};
export const getAccessTokensFromRefreshToken = async (
    refreshToken: string,
    oktaIssuer?: string, // need default value for now to make sure old code/workflow doesn't break
    oktaAppClientId?: string // need default value for now to make sure old code/workflow doesn't break
): Promise<SsoSessionKeys> => {
    const issuer = oktaIssuer || getCookie(OrionCookies.oktaIssuer) || SSO_OKTA_BASE;
    const clientId = oktaAppClientId || getCookie(OrionCookies.oktaClientId) || CLIENT_ID;

    const getOktaTokensFromCodeUrl = `${issuer}/oauth2/v1/token`;

    const response = await axios.post(
        getOktaTokensFromCodeUrl,
        getParamsForTokensFromRefreshToken(refreshToken, clientId),
        {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'accept': 'application/json',
                'cache-control': 'no-cache',
            },
        }
    );
    const { id_token: idToken, access_token: accessToken, refresh_token: newRefreshToken, expiry } = response.data;
    return {
        idToken,
        accessToken,
        refreshToken: newRefreshToken,
        expiry,
    };
};

export const oktaRedirectToOktaAuthenticationViaIdToken = (
    oktaIssuer?: string,
    oktaAppClientId?: string,
    codeChallenge?: string,
    codeChallengeMethod?: string
): void => {
    const issuer = oktaIssuer || getCookie(OrionCookies.oktaIssuer) || SSO_OKTA_BASE;
    const clientId = oktaAppClientId || getCookie(OrionCookies.oktaClientId) || CLIENT_ID;

    const state = `state-${uuid()}`;
    setCookie(OrionCookies.oktaState, state, 200);

    const queryParamsObj = {
        client_id: clientId,
        response_type: 'id_token token code',
        nonce: uuid(),
        scope: 'openid profile offline_access',
        state: state,
        redirect_uri: `${document.location.protocol}//${document.location.host}/sso/okta`,
        code_challenge: codeChallenge,
        code_challenge_method: codeChallengeMethod,
    };
    const queryParams = map(keys(queryParamsObj), (key) => `${key}=${encodeURIComponent(queryParamsObj[key])}`).join(
        '&'
    );
    document.location.href = `${issuer}/oauth2/v1/authorize?${queryParams}`;
};

export const oktaRelogin = (idToken: string): void => {
    const idTokenDecoded = jwtDecode(idToken);
    const issuer = getCookie(OrionCookies.oktaIssuer) || idTokenDecoded?.['iss'] || SSO_OKTA_BASE;
    document.location.href = `${issuer}/oauth2/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${document.location.protocol}//${document.location.host}/sso/okta/relogin`;
};

export const oktaTokenAuthUrl = (oktaIssuer?: string): string => {
    const issuer = oktaIssuer || getCookie(OrionCookies.oktaIssuer) || SSO_OKTA_BASE;
    return `${issuer}/api/v1/authn`;
};

export const oktaSLO = (idToken: string): void => {
    const idTokenDecoded = jwtDecode(idToken);
    const issuer = getCookie(OrionCookies.oktaIssuer) || idTokenDecoded?.['iss'] || SSO_OKTA_BASE;
    document.location.href = `${issuer}/oauth2/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${document.location.protocol}//${document.location.host}/sso/okta/logout`;
};

export const getOktaDashboardUrl = (issuer: string): string => {
    return `${issuer}/app/UserHome`;
};

export async function generateCodeChallenge(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashed = await crypto.subtle.digest('SHA-256', data);
    const base64encoded = window.btoa(String.fromCharCode(...new Uint8Array(hashed)));
    const base64url = replace(replace(replace(base64encoded, /=/g, ''), /\+/g, '-'), /\//g, '_');
    return base64url;
}
