import { useCallback, useEffect, useRef } from 'react';

export type IsMounted = () => boolean;
export const useIsMounted = (): (() => boolean) => {
    const isMounted = useRef(true);
    useEffect(() => {
        isMounted.current = true;
        return (): void => {
            isMounted.current = false;
        };
    }, []);

    return useCallback((): boolean => isMounted.current, []);
};
