import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { ComponentWithoutHOCProps } from 'common/types/component';
import { identity, mapValues, reduce, some } from 'lodash';
import { ComponentType, CSSProperties, FC, lazy, ReactElement, ReactNode, useCallback, useRef, useState } from 'react';
import './loading.scss';

export function preloadLazyComponent(
    factory: () => Promise<{ default: ComponentType<unknown> | ComponentWithoutHOCProps<unknown> }>
): unknown {
    const Component = lazy(factory) as unknown as Record<string, unknown>;
    Component.preload = factory;
    return Component;
}

export function useLoading(...loadingKeys): WithLoadingProps {
    const [isLoading, setIsLoading] = useState(true);
    const loading = useRef(
        reduce(
            loadingKeys,
            (obj, key): { [key: string]: boolean } => {
                obj[key] = true;
                return obj;
            },
            {}
        )
    );

    function setLoading(targetValue: boolean, loadingKey?: string): void {
        if (loadingKey != null) {
            if (loading.current[loadingKey] == null) {
                throw new Error('Unknown loadingKey!');
            }
            loading.current[loadingKey] = targetValue;
        } else {
            loading.current = mapValues(loading.current, (): boolean => targetValue);
        }
        const isLoading = targetValue || some(loading.current, identity);
        setIsLoading(isLoading);
    }

    const setLoadingDone = useCallback((loadingKey?: string): void => {
        setLoading(false, loadingKey);
    }, []);

    const setLoadingStart = useCallback((loadingKey?: string): void => {
        setLoading(true, loadingKey);
    }, []);

    return {
        isLoading,
        setLoadingDone,
        setLoadingStart,
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withLoading(...loadingKeys) {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return function <T extends WithLoadingProps>(WrappedComponent: FC<T>) {
        return function (props): ReactElement {
            const { isLoading, setLoadingStart, setLoadingDone } = useLoading(...loadingKeys);
            return (
                <WrappedComponent
                    {...props}
                    isLoading={isLoading}
                    setLoadingDone={setLoadingDone}
                    setLoadingStart={setLoadingStart}
                />
            );
        };
    };
}

export interface WithLoadingProps {
    isLoading: boolean;
    setLoadingDone: (loadingKey?: string) => void;
    setLoadingStart: (loadingKey?: string) => void;
}

interface LoadingProps {
    children?: ReactNode;
    isLoading: boolean;
    style?: CSSProperties;
}

export function Loading({ children, isLoading, style }: LoadingProps): ReactElement {
    if (isLoading) {
        return <CircularProgress data-testid="loadingSpinner" style={style} />;
    }
    return children ? <>{children}</> : null;
}

export function LoadingOverlay({ children, isLoading, style }: LoadingProps): ReactElement {
    return (
        <div className="loading-overlay">
            {isLoading && (
                <div className="loading-overlay__indicator-container">
                    <CircularProgress data-testid="loadingSpinner" style={style} />
                </div>
            )}
            <div
                aria-disabled={isLoading}
                className={classNames('loading-overlay__content', {
                    'loading-overlay__content--loading': isLoading,
                })}
            >
                {children}
            </div>
        </div>
    );
}

/**
 * Just display Loading indicator for now, if needed we can display
 * 'Loading' text along with this (which should be translatable)
 * @returns Loading Indicator
 */
export function SuspenseFallback(): ReactElement {
    return (
        <div className="loading-overlay__suspense">
            <div className="loading-overlay__indicator-container">
                <CircularProgress />
            </div>
        </div>
    );
}
