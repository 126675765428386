import { addDate, getUnixTimeStamp, isBeforeDate } from '@orion/fe-utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function readCacheItem<T>(key: string): T {
    let cacheItem = null;

    try {
        let _cacheItem = JSON.parse(window.sessionStorage.getItem(key));
        if (_cacheItem === null) {
            _cacheItem = JSON.parse(window.localStorage.getItem(key));
        }

        if (_cacheItem.exp) {
            if (!_cacheItem || isBeforeDate(_cacheItem.exp, new Date())) {
                _cacheItem = null;
            } else {
                cacheItem = _cacheItem.data;
            }
        }
    } catch (err) {}

    return cacheItem;
}

export function writeCacheItem<T>(key: string, value: T, sessionScope: boolean, { amount, unit }): void {
    let cache = window.localStorage;
    let cacheItem = JSON.stringify(value);
    let expires = null;

    if (sessionScope) {
        cache = window.sessionStorage;
    } else if (amount || unit) {
        expires = getUnixTimeStamp(addDate(amount, unit));
        cacheItem = JSON.stringify({
            data: value,
            exp: expires,
        });
    }

    cache.setItem(key, cacheItem);
}
