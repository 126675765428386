import Bowser from 'bowser';
import { AuthState } from 'common/auth/auth_context';
import { forEach, get as _get, parseInt } from 'lodash';
import { ComponentType, createContext, FC, memo, ReactElement } from 'react';
import { connect } from 'react-redux';

export interface Logger {
    initialize();

    log(...e): void;
    info(...e): void;
    warn(...e): void;
    error(...e): void;
}

export interface UserMeta {
    userPk?: string;
    sessionId?: string;
    browser?: object;
    engine?: object;
    os?: object;
    platform?: object;
    location?: string;
    hostname?: string;
}

export class CILogger {
    private static __instance: CILogger = new CILogger();

    public static getInstance(): CILogger {
        return CILogger.__instance;
    }

    private providers: Logger[] = [];
    private logLevel: number = parseInt(process.env.REACT_APP_LOGLEVEL);
    private currentSession: UserMeta = {};

    public setAuth(auth): void {
        this.currentSession = {
            userPk: (auth && auth.pk) || null,
            sessionId: (auth && auth.sessionId) || null,
            hostname: _get(window, 'location.hostname'),
            location: _get(window, 'location.href'),
            ...Bowser.parse(window.navigator.userAgent),
        };
    }

    // Append/Update log data
    private appendDefaultLogData(): void {
        this.currentSession.location = _get(window, 'location.href');
    }

    public initialize(): void {
        forEach(this.providers, (provider): void => provider.initialize());
    }

    public registerProvider(provider: Logger): void {
        this.providers.push(provider);
    }

    public deregisterProvider(provider: Logger): void {
        for (let i = 0; i < this.providers.length; i++) {
            if (this.providers[i] === provider) {
                delete this.providers[i];
                break;
            }
        }
    }

    public log(...e): void {
        if (this.logLevel === 0) {
            this.appendDefaultLogData();
            forEach(this.providers, (provider): void => provider.log(e, this.currentSession));
        }
    }

    public info(...e): void {
        if (this.logLevel <= 1) {
            this.appendDefaultLogData();
            forEach(this.providers, (provider): void => provider.info(e, this.currentSession));
        }
    }

    public warn(...e): void {
        if (this.logLevel <= 2) {
            this.appendDefaultLogData();
            forEach(this.providers, (provider): void => provider.warn(e, this.currentSession));
        }
    }

    public error(...e): void {
        if (this.logLevel <= 3) {
            this.appendDefaultLogData();
            forEach(this.providers, (provider): void => provider.error(e, this.currentSession));
        }
    }
}

export const LoggingContext = createContext(CILogger.prototype);

export default function LoggerProvider(WrappedComponent): ComponentType {
    interface Props {
        auth: AuthState;
    }

    const _LoggerProvider: FC<Props> = (props): ReactElement => {
        const logger = CILogger.getInstance();
        logger.setAuth(props.auth.activeUser);

        return (
            <LoggingContext.Provider value={logger}>
                <WrappedComponent />
            </LoggingContext.Provider>
        );
    };

    const LoggerProvider = memo(_LoggerProvider, (prevProps, nextProps): boolean => nextProps.auth === prevProps.auth);

    const mapStateToProps = ({ auth }): object => ({ auth });

    return connect(mapStateToProps)(LoggerProvider);
}

export function withLogger(Component): ComponentType {
    return function WrapperComponent(props): ReactElement {
        return (
            <LoggingContext.Consumer>
                {(logger): ReactElement => <Component {...props} logger={logger} />}
            </LoggingContext.Consumer>
        );
    };
}
