import { gql } from '@apollo/client';
import { Device } from '@capacitor/device';
import { AuthUser } from 'common/auth/auth_context';
import { CIError } from 'common/error/error_util';
import gqlClient from 'common/graphql/client';
import { Device as DeviceDef } from 'common/graphql/types';
import { find, head } from 'lodash';
import { DeviceInitializerPlugin, RSAPlugin } from '../ci-native-plugins/CIPlugins';

export const listDevicesForSerialNumber = `query listDevicesForSerialNumber($serialNumber:String!) {
    listDevices ( filter :{
      serialNumber :{eq: $serialNumber}
    }) {
      items {pk sk type serialNumber isAssigned listOfSites{siteId} listOfStudies{studyId} }
    }
  }`;

const createByodDevice = gql`
    mutation createByodDevice(
        $serialNumber: String!
        $deviceOS: String!
        $deviceOSVersion: String!
        $deviceModel: String!
        $deviceManufacturer: String!
    ) {
        createDevice(
            input: {
                serialNumber: $serialNumber
                deviceOS: $deviceOS
                deviceOSVersion: $deviceOSVersion
                deviceModel: $deviceModel
                deviceManufacturer: $deviceManufacturer
                type: BYOD
                status: ACTIVE
                deviceType: TABLET
            }
        ) {
            pk
            sk
            salesforceId
            serialNumber
            isAssigned
            version
            studyId
            siteId
            deviceModel
            deviceType
        }
    }
`;

const upsertUserPublicKey = gql`
    mutation upsertUserPublicKey($userId: String!, $deviceId: String!, $key: String!) {
        upsertUserPublicKey(userId: $userId, deviceId: $deviceId, publicKey: $key) {
            deviceId
            key
        }
    }
`;

const setDevicePublicKey = gql`
    mutation setDevicePublicKey($deviceId: String!, $key: String!) {
        setDevicePublicKey(deviceId: $deviceId, publicKey: $key) {
            deviceId
            key
        }
    }
`;

export class NativeDeviceInitializer {
    static ALIAS_DEVICE_KEY = 'com.orion.site.device';

    /**
     * Saves the device information to the server and upserts the public key per user at server if not already
     * @param {AuthUser} authUser
     * @return {*}  {Promise<DeviceDef>}
     */
    static async initDevice(authUser: AuthUser): Promise<DeviceDef> {
        let serialNumber: string = await NativeDeviceInitializer.getDeviceSerialNumber();
        let device = await NativeDeviceInitializer.getDeviceFromServer(serialNumber);
        if (!device) {
            // its BYOD register the device
            device = await NativeDeviceInitializer.registerByod(serialNumber);
        }

        await DeviceInitializerPlugin.saveDevice<DeviceDef>({ device });
        await NativeDeviceInitializer.setupUserPublickKey(authUser, device);
        await NativeDeviceInitializer.setupDevicePublickKey(device);

        return device;
    }

    private static async setupDevicePublickKey(device: DeviceDef): Promise<void> {
        let devicePublicKey: string | null = (
            await RSAPlugin.getPublicKey({ alias: NativeDeviceInitializer.ALIAS_DEVICE_KEY })
        ).publicKey;

        if (!devicePublicKey) {
            await RSAPlugin.generateKeyPair({ alias: NativeDeviceInitializer.ALIAS_DEVICE_KEY });
            devicePublicKey = (await RSAPlugin.getPublicKey({ alias: NativeDeviceInitializer.ALIAS_DEVICE_KEY }))
                .publicKey;
        }
        if (devicePublicKey) {
            devicePublicKey = NativeDeviceInitializer.formatToPublicPEM(devicePublicKey);

            // upload the public key to server for device
            try {
                await gqlClient.mutate({
                    mutation: setDevicePublicKey,
                    variables: {
                        deviceId: device.pk,
                        key: devicePublicKey,
                    },
                });
            } catch (error) {
                throw CIError.fromGraphQLError(error);
            }
        }
    }

    private static async setupUserPublickKey(authUser: AuthUser, device: DeviceDef): Promise<void> {
        const publicKeyAtServer = find(authUser.publicKeys, ['deviceId', device.pk]);
        let publicKey: string | null = (await RSAPlugin.getPublicKey({ alias: authUser.pk })).publicKey;
        if (!publicKey) {
            await RSAPlugin.generateKeyPair({ alias: authUser.pk });
            publicKey = (await RSAPlugin.getPublicKey({ alias: authUser.pk })).publicKey;
        }
        publicKey = NativeDeviceInitializer.formatToPublicPEM(publicKey);
        /**
         * If there is no public key at server then upload it
         * if app is installed again at same device then publicKey can be different so update it
         */
        if (!publicKeyAtServer || publicKeyAtServer.key !== publicKey) {
            // upsert the public key at server
            try {
                await gqlClient.mutate({
                    mutation: upsertUserPublicKey,
                    variables: {
                        userId: authUser.pk,
                        deviceId: device.pk,
                        key: publicKey,
                    },
                });
            } catch (error) {
                throw CIError.fromGraphQLError(error);
            }
        }
    }

    private static async registerByod(serialNumber: string): Promise<DeviceDef> {
        const deviceInfo = await Device.getInfo();
        const deviceOS = deviceInfo.platform;
        const deviceOSVersion = deviceInfo.osVersion;
        const deviceModel = deviceInfo.model;
        const deviceManufacturer = deviceInfo.manufacturer;

        const result = await gqlClient.mutate({
            mutation: createByodDevice,
            variables: {
                serialNumber,
                deviceOS,
                deviceOSVersion,
                deviceModel,
                deviceManufacturer,
            },
        });
        const device = result.data.createDevice;
        return device;
    }

    static async getDeviceFromServer(serialNumber: string): Promise<DeviceDef | null> {
        try {
            const result = await gqlClient
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .query<any>({
                    query: gql`
                        ${listDevicesForSerialNumber}
                    `,
                    variables: { serialNumber: serialNumber },
                    fetchPolicy: 'network-only',
                });

            const devices = result.data.listDevices.items;
            return head(devices);
        } catch (error) {
            throw CIError.fromGraphQLError(error);
        }
    }

    static async getDeviceSerialNumber(): Promise<string> {
        try {
            const { deviceUniqueId } = await DeviceInitializerPlugin.getDeviceUniqueId();
            return deviceUniqueId;
        } catch (error) {
            throw CIError.fromNativeError({
                code: error.code,
                message: error.message,
            });
        }
    }

    static formatToPublicPEM(publickKeyEncoded: string): string {
        return `-----BEGIN PUBLIC KEY-----\n${publickKeyEncoded}\n-----END PUBLIC KEY-----`;
    }
}
