import { PatientIdFieldWithPatientPrefix, PatientIdFieldWithoutPatientPrefix } from 'common/types/patientIds';

const STORED_DEFAULT_PATIENT_ID = 'patientListingDefaultPatientId';
const STORED_DEFAULT_EOS_PATIENT_ID = 'patientListingDefaultEosPatientId';

const getParsedValue = (key: string) => JSON.parse(sessionStorage.getItem(key));

const setPatientIdInSessionStorage = (key: string, studyPk: string, patientId: any): void => {
    sessionStorage.setItem(key, JSON.stringify({ ...getParsedValue(key), [studyPk]: patientId }));
};

const createPatientIdSelectionStorage = (storageKey: string) => ({
    get: <T>(studyPk: string) => getParsedValue(storageKey)?.[studyPk] as T,
    set: (
        studyPk: string,
        { patientId }: { patientId: PatientIdFieldWithPatientPrefix | PatientIdFieldWithoutPatientPrefix }
    ): void => setPatientIdInSessionStorage(storageKey, studyPk, patientId),
    remove: (): void => {
        sessionStorage.removeItem(storageKey);
    },
});

export const patientIdSelectionStorage = createPatientIdSelectionStorage(STORED_DEFAULT_PATIENT_ID);

export const patientIdEosSelectionStorage = createPatientIdSelectionStorage(STORED_DEFAULT_EOS_PATIENT_ID);
