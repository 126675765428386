import { Close as CloseIcon } from '@mui/icons-material';
import {
    BackgroundBlurProvider,
    BackgroundReplacementProvider,
    MeetingProvider,
    useVoiceFocus,
    VoiceFocusProvider,
} from 'amazon-chime-sdk-component-library-react';
import { AudioTransformDevice, Device, VoiceFocusModelName, VoiceFocusTransformDevice } from 'amazon-chime-sdk-js';
import MeetingForm from 'common/telehealth/containers/MeetingForm';
import { LoadingOverlay } from 'common/utils/loading';
import { CIIconButton } from 'common/viewcomp/forms/buttons';
import { includes } from 'lodash';
import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import meetingConfig from '../config';
import { useAppState } from '../providers/AppStateProvider';
import { NavigationProvider } from '../providers/NavigationProvider';
import { VideoFiltersCpuUtilization } from '../types';

const MeetingProviderWithDeviceReplacement: FC = ({ children }) => {
    const { addVoiceFocus } = useVoiceFocus();

    const onDeviceReplacement = (
        nextDevice: string,
        currentDevice: Device | AudioTransformDevice
    ): Promise<Device | VoiceFocusTransformDevice> => {
        if (currentDevice instanceof VoiceFocusTransformDevice) {
            return addVoiceFocus(nextDevice);
        }
        return Promise.resolve(nextDevice);
    };

    const meetingConfigValue = {
        ...meetingConfig,
        enableWebAudio: true,
        onDeviceReplacement,
    };

    return <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>;
};

function MeetingProviderWrapper(props): ReactElement {
    const {
        isWebAudioEnabled,
        videoTransformCpuUtilization,
        imageBlob,
        joinInfo,
        setIsAdmin,
        setMeetingId,
        setLocalUserName,
        close,
    } = useAppState();
    const { t } = useTranslation('global');

    const isFilterEnabled = videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;

    const meetingConfigValue = {
        ...meetingConfig,
        enableWebAudio: isWebAudioEnabled,
    };

    const getMeetingProviderWrapper = () => {
        return (
            <NavigationProvider>
                <CIIconButton
                    id="close-televisit"
                    onClick={close}
                    icon={CloseIcon}
                    aria-label={t('telehealth.close')}
                    tooltip={t('telehealth.close')}
                    className="close-meeting"
                />
                <MeetingForm />
            </NavigationProvider>
        );
    };

    function voiceFocusName(name: string): VoiceFocusModelName {
        if (name && includes(['default', 'ns_es'], name)) {
            return name as VoiceFocusModelName;
        }
        return 'default';
    }

    function getVoiceFocusSpecName(): VoiceFocusModelName {
        if (joinInfo && joinInfo.Meeting?.MeetingFeatures?.Audio?.EchoReduction === 'AVAILABLE') {
            return voiceFocusName('ns_es');
        }
        return voiceFocusName('default');
    }

    const vfConfigValue = {
        spec: { name: getVoiceFocusSpecName() },
        createMeetingResponse: joinInfo,
    };

    const getMeetingProviderWrapperWithVF = (children: ReactNode) => {
        return (
            <VoiceFocusProvider {...vfConfigValue}>
                <MeetingProviderWithDeviceReplacement>{children}</MeetingProviderWithDeviceReplacement>
            </VoiceFocusProvider>
        );
    };

    const getWrapperWithVideoFilter = (children: ReactNode) => {
        let filterCPUUtilization = parseInt(videoTransformCpuUtilization, 10);
        if (!filterCPUUtilization) {
            filterCPUUtilization = 40;
        }
        return (
            <BackgroundBlurProvider options={{ filterCPUUtilization }}>
                <BackgroundReplacementProvider options={{ imageBlob, filterCPUUtilization }}>
                    {children}
                </BackgroundReplacementProvider>
            </BackgroundBlurProvider>
        );
    };

    const getMeetingProvider = (children: ReactNode) => {
        return <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>;
    };

    const getMeetingProviderWithFeatures = (): ReactNode => {
        let children = getMeetingProviderWrapper();

        if (isFilterEnabled) {
            children = getWrapperWithVideoFilter(children);
        }
        if (isWebAudioEnabled) {
            children = getMeetingProviderWrapperWithVF(children);
        } else {
            children = getMeetingProvider(children);
        }
        return children;
    };

    useEffect(() => {
        setMeetingId(props.id);
        setLocalUserName(props.name);
        setIsAdmin(props.isAdmin);
    }, []);

    return (
        <div className="meeting-container">
            <LoadingOverlay isLoading={imageBlob === undefined}>{getMeetingProviderWithFeatures()}</LoadingOverlay>
        </div>
    );
}

export default MeetingProviderWrapper;
