import { ApolloClient, from, HttpLink, InMemoryCache, Operation } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { Capacitor } from '@capacitor/core';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { AuthUser } from 'common/auth/auth_context';
import { getOktaUserPool } from 'common/auth/sso/oktaUserPool';
import { CIError } from 'common/error/error_util';
import { OfflineManager } from 'common/native-app-support/native.offline.manager';
import { latencyLookupClient } from 'common/rest/client';
import { readCacheItem, writeCacheItem } from 'common/utils/cache';
import { CILogger } from '../log/log.provider';
import { LocalGraphQLQueryResolverLink } from './local_graphql_query_resolver_link';

export type ListResponse<PropertyName extends string, DataType> = {
    [K in PropertyName]: {
        items: DataType[];
        totalItems: number;
        totalItemsWithoutFilters?: number;
    };
};

type LatencyRouterResponse = { uri: string; apiKey: string; authorizationToken: string };

export interface OfflineContext {
    /*supported true means this GrpahQL query is implemented in native tablet app and can be resloved offline*/
    supported: boolean;
    /* fallbackPolicy 'native' means if a query is tried online and failed due to some issue, 
    it will be retried from Local DB irrespective of application is Offline or Connected

    fallbackPolicy 'none' means if query will be retried on the same source either web or native only based
    on the source of first time call*/
    fallbackPolicy?: 'native' | 'none';
    scheme?: 'offline-first' | 'online-first';
}

export function getCognitoUser(): AmazonCognitoIdentity.CognitoUser {
    const poolData = {
        UserPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
        ClientId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_CLIENTID,
        Storage: window.localStorage,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    return userPool.getCurrentUser();
}

export function getAuthUser(): AuthUser {
    const activeUser: string = localStorage.getItem('activeUser');
    return JSON.parse(activeUser) as AuthUser;
}

export function getAuthenticationMethod(): 'COGNITO' | 'OKTA' {
    if (getCognitoUser()) {
        return 'COGNITO';
    }

    return getAuthUser().authenticationMethod;
}

export function getSessionIdToken(): Promise<string> {
    return new Promise<string>((resolve, reject): void => {
        const cognitoUser = getCognitoUser();
        /**
         * On native mobile devices when users logged in offline but later came online
         * then return idToken from stored activeUser as cognitoUser will be null.
         * TODO :- Make native app to interact only from  local db irrespective of connection or not.
         */
        if (cognitoUser === null) {
            if (getAuthenticationMethod() === 'OKTA') {
                const { currentSession } = getOktaUserPool();
                if (currentSession) {
                    currentSession.getIdToken().then((idToken) => resolve(idToken));
                } else {
                    resolve(getAuthUser().auth.idToken);
                }
            } else {
                resolve(getAuthUser().auth.idToken);
                return;
            }
        } else {
            cognitoUser.getSession((err, session): void => {
                if (err) {
                    reject(CIError.fromCognitoError(err));
                } else {
                    const idToken: string = session.getIdToken().jwtToken;

                    resolve(idToken);
                }
            });
        }
    });
}

/**
 * Resets cognito's lastAuthUser key to valid user and deletes the stored keys
 * of Invalid user from localStorage
 * @param validUserId Previous Valid User
 * @param invalidUserId Invalid User who dont have access while switching user
 */
export function resetLastAuthUser(validUserId: string, invalidUserId: string): void {
    const clientId = process.env.REACT_APP_AWS_COGNITO_USERPOOL_CLIENTID;
    const cognitoKey = 'CognitoIdentityServiceProvider.' + clientId;

    // Replace lastAuthUser value with the validUserId

    localStorage.setItem(`${cognitoKey}.LastAuthUser`, validUserId);

    // Delete Saved Keys of Invalid User

    localStorage.removeItem(`${cognitoKey}.${invalidUserId}.clockDrift`);
    localStorage.removeItem(`${cognitoKey}.${invalidUserId}.idToken`);
    localStorage.removeItem(`${cognitoKey}.${invalidUserId}.refreshToken`);
    localStorage.removeItem(`${cognitoKey}.${invalidUserId}.accessToken`);
}

const logger: CILogger = CILogger.getInstance();

const LATENCY_RESULT_CACHE_KEY = 'ciLatencyResult';
const getLatencyRoutingEndpointAndKey = (): Promise<LatencyRouterResponse> => {
    const endPointApiKey = readCacheItem<LatencyRouterResponse>(LATENCY_RESULT_CACHE_KEY);

    if (!endPointApiKey && !!process.env.REACT_APP_AWS_APPSYNC_ROUTING_ENDPOINT) {
        return latencyLookupClient.get('').then((res): LatencyRouterResponse => {
            const endpoint = res?.data?.appsyncService as string;
            const apiKey = res?.data?.apikey as string;
            const authorizationToken = res?.data?.authorizationToken as string;

            writeCacheItem(LATENCY_RESULT_CACHE_KEY, { uri: endpoint, apiKey, authorizationToken }, false, {
                amount: 2,
                unit: 'hours',
            });

            return { uri: endpoint, apiKey, authorizationToken };
        });
    }
    return new Promise<LatencyRouterResponse>((resolve): void => {
        resolve(endPointApiKey);
    });
};

getLatencyRoutingEndpointAndKey().then();

const setUriLink = setContext(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_, { region }): any | Promise<any> => {
        if (!region) {
            return getLatencyRoutingEndpointAndKey().then(({ uri }): { uri: string } => ({ uri }));
        }

        let contextEndpoint = process.env['REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT_' + region];
        if (!contextEndpoint) {
            // TODO: Log error here through logger that environment doesn't have the defined region configured
            contextEndpoint = '';
        }

        return { uri: contextEndpoint };
    }
);

const httpLink = from([setUriLink, new HttpLink({ uri: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT })]);
const localGraphQLQueryResolverLink = new LocalGraphQLQueryResolverLink();

// eslint-disable-next-line lodash/prefer-lodash-method
const nativeHttpLink = new RetryLink({
    delay: {
        initial: 1000,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 2,
        retryIf: (error, operation: Operation): boolean => {
            const isNetworkError = error.stack === 'TypeError: Failed to fetch';
            const context = operation.getContext();
            const offlineContext: OfflineContext = context.offlineContext;

            const shouldRetry = isNetworkError && offlineContext && offlineContext.fallbackPolicy === 'native';

            if (shouldRetry) {
                // set context to force this request to be resloved by localGraphQLQueryResolverLink
                context.retryWithNative = true;
                operation.setContext(context);
            }

            return shouldRetry;
        },
    },
}).split(
    (operation) => {
        const context = operation.getContext();
        const offlineContext: OfflineContext = context['offlineContext'];
        return (
            (offlineContext?.supported &&
                (OfflineManager.getInstance().isOfflineModeActive() || offlineContext?.scheme === 'offline-first')) ||
            context.retryWithNative === true
        );
    },
    localGraphQLQueryResolverLink,
    from([httpLink])
);

const errorLink = onError(({ graphQLErrors }): void => {
    if (graphQLErrors) {
        logger.warn(`Encountered graphQL errors, this is often a code issue`, graphQLErrors);
    }
});

const setCognitoAuthHeaderLink = setContext((_, previousContext): Promise<any> => {
    const { headers, generateFields, specificHeaders } = previousContext;
    return getSessionIdToken().then((token): object => {
        const _headers = {
            authorization: token,
            'orion-audit-originator': 'WEB',
            ...(headers || {}),
        };
        if (generateFields) {
            _headers['x-generate-fields'] = JSON.stringify(generateFields);
        }
        if (specificHeaders) {
            for (const key in specificHeaders) {
                _headers[key] = specificHeaders[key];
            }
        }
        return {
            headers: _headers,
        };
    });
});

const getLatencyRoutingApiKey = (): Promise<object> =>
    getLatencyRoutingEndpointAndKey()
        .then(({ apiKey, authorizationToken }): object => ({
            headers: {
                'Authorization': authorizationToken || process.env.REACT_APP_AWS_APPSYNC_AUTHORIZATION_APIKEY,
                'x-api-key':
                    !authorizationToken && !process.env.REACT_APP_AWS_APPSYNC_AUTHORIZATION_APIKEY
                        ? apiKey || process.env.REACT_APP_AWS_APPSYNC_LOGGER_APIKEY
                        : '',
                'x-applicationlogerkey': process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
                'orion-audit-originator': 'WEB',
            },
        }))
        .catch((): object => ({
            headers: {
                'Authorization': process.env.REACT_APP_AWS_APPSYNC_AUTHORIZATION_APIKEY,
                'x-api-key': !process.env.REACT_APP_AWS_APPSYNC_AUTHORIZATION_APIKEY
                    ? process.env.REACT_APP_AWS_APPSYNC_LOGGER_APIKEY
                    : '',
                'x-applicationlogerkey': process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
                'orion-audit-originator': 'WEB',
            },
        }));

const setNonAuthHeaderLink = setContext((): Promise<any> => getLatencyRoutingApiKey());

const setLoggerHeaderLink = setContext(
    (): Promise<any> =>
        new Promise((resolve) => {
            getSessionIdToken()
                .then((token): void => {
                    resolve({
                        headers: {
                            authorization: token,
                        },
                    });
                })
                .catch((): void => {
                    getLatencyRoutingApiKey().then((headers) => resolve(headers));
                });
        })
);

const client = new ApolloClient({
    // TODO: Apply latency routing rules through subscriptions (should we ever use them...)
    link: errorLink.concat(
        from([
            setCognitoAuthHeaderLink,
            createSubscriptionHandshakeLink(
                process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
                Capacitor.isNativePlatform() ? nativeHttpLink : httpLink
            ),
        ])
    ),
    cache: new InMemoryCache({
        addTypename: false,
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
        },
        mutate: {
            fetchPolicy: 'no-cache',
        },
    },
});
export default client;

export const loggerClient = new ApolloClient({
    link: errorLink.concat(
        from([
            setLoggerHeaderLink,
            createSubscriptionHandshakeLink(
                process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
                Capacitor.isNativePlatform() ? nativeHttpLink : httpLink
            ),
        ])
    ),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
        },
        mutate: {
            fetchPolicy: 'no-cache',
        },
    },
});

export const nonAuthClient = new ApolloClient({
    link: errorLink.concat(
        from([
            setNonAuthHeaderLink,
            createSubscriptionHandshakeLink(process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT, httpLink),
        ])
    ),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
        },
        mutate: {
            fetchPolicy: 'no-cache',
        },
    },
});
