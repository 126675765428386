import { Icon } from '@mui/material';
import withTranslate from 'common/i18n/i18n';
import { CISecondaryButton } from 'common/viewcomp/forms/buttons';
import { History } from 'history';
import notFoundImg from 'img/logos/404_icon.svg';
import { flow } from 'lodash';
import { withAuthLayout } from 'module/auth/login/authLayout.page';
import { Component, ReactNode } from 'react';
import { withRouter } from 'react-router';
import './studydeleted.scss';

interface Props {
    t: Function;
    history: History;
}

export class StudyDeleted extends Component<Props> {
    public constructor(props) {
        super(props);
        this.backtoLogin = this.backtoLogin.bind(this);
    }

    private backtoLogin(): void {
        this.props.history.push('/login');
    }
    /**
     * Render component to screen
     */
    public render(): ReactNode {
        const t = this.props.t;
        return (
            <div id="study_not_found">
                <Icon>
                    <img src={notFoundImg} alt="" />
                </Icon>
                <p>{t('study_not_found.study_deleted')}</p>
                <p>{t('study_not_found.contact_support')}</p>
                <CISecondaryButton className="back_login" type="submit" id="back_login" onClick={this.backtoLogin}>
                    {t('study_not_found.back_login')}
                </CISecondaryButton>
            </div>
        );
    }
}

export default flow([withAuthLayout, withRouter, withTranslate('global')])(StudyDeleted);
