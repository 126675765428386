import Navigation from '.';
import { useNavigation } from '../../providers/NavigationProvider';
import MeetingRoster from '../MeetingRoster';

const NavigationControl = () => {
    const { showNavbar, showRoster } = useNavigation();

    return (
        <>
            {showNavbar ? <Navigation /> : null}
            {showRoster ? <MeetingRoster /> : null}
        </>
    );
};

export default NavigationControl;
