import { Flex, Heading } from 'amazon-chime-sdk-component-library-react';
import { replace } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../providers/AppStateProvider';
import { StyledList } from './Styled';

const MeetingDetails = () => {
    const { t } = useTranslation('global');
    const { meetingId, isAdmin } = useAppState();

    return (
        <Flex container layout="fill-space-centered">
            <Flex>
                <Heading level={4} tag="h1">
                    {isAdmin
                        ? t('telehealth.waiting_room.admin_message')
                        : t('telehealth.waiting_room.patient_message')}
                </Heading>
                <StyledList>
                    <dt>{t('telehealth.id', { id: replace(meetingId, 'TelehealthMeeting.', '') })}</dt>
                </StyledList>
            </Flex>
        </Flex>
    );
};

export default MeetingDetails;
