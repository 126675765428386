import { RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export const routes = [
    {
        component: lazy(() => import('module/eosdashboard/EosSiteDashboardPage')),
        path: '/eosDashboard',
        permissions: ['EOSDownload', 'EOSDownloadRecord_Read'],
        role: [
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
        ],
        studySiteRequired: true,
    },
    {
        component: lazy(() => import('module/eosdashboard/EosSponsorDashboardPage')),
        path: '/sponsors/eosDashboard/:studyId/:siteId',
        permissions: ['EOSDownload', 'EOSDownloadRecord_Read'],
        role: [RoleVariantEnum.Monitor, RoleVariantEnum.DataManager],
        studySiteRequired: false,
    },
];
