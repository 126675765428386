import { authReducers as auth, ReduxLogic } from 'common/auth/auth_context';
import { networkReducer as network } from 'common/network-status/network_status_context';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogicMiddleware } from 'redux-logic';

export type RootState = ReturnType<typeof store.getState>;

export const store = createStore(
    combineReducers({
        auth,
        network,
    }),
    applyMiddleware(createLogicMiddleware([...ReduxLogic]))
);
