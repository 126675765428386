import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/site/SiteListPage')),
        permissions: ['Site_Read', 'Site_Create', 'Site_Edit', 'Site_Delete'],
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.SystemAdminstrator,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/',
    },
];
