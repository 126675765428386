import { toLower } from 'lodash';
import moment from 'moment';

export const importDateLocale = async (lang: string) => {
    const languageKey = toLower(lang);

    try {
        await import(`../../../node_modules/moment/dist/locale/${languageKey}.js`);
        moment.locale(languageKey);
    } catch (e) {
        const strippedLang = languageKey.substring(0, 2);

        try {
            await import(`../../../node_modules/moment/dist/locale/${strippedLang}.js`);
            moment.locale(languageKey);
        } catch (err) {
            try {
                await import(`../i18n/locales/${strippedLang}-moment.ts`);
                moment.locale(languageKey);
            } catch (r) {}
        }
    }
};
