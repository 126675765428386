import { ApolloLink, Observable, Observer, Operation } from '@apollo/client';
import { QueryResolverPlugin } from 'common/native-app-support/ci-native-plugins/CIPlugins';
import { print } from 'graphql/language/printer';

// Terminating Link to send GraphQL queries in native code via capacitor plugin instead of sending then to server using http.
export class LocalGraphQLQueryResolverLink extends ApolloLink {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request(operation: Operation): Observable<any> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Observable((observer: Observer<any>): any => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryResolverPlugin.resolve<any>({
                query: print(operation.query),
                variables: JSON.stringify(operation.variables),
            })
                .then((result) => {
                    // add extra field to distinguish the sourceOfData
                    result.data['isFromNative'] = true;
                    observer.next(result);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                });

            return (): void => {};
        });
    }
}
