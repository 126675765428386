/* These enums are to be used while creating audit events.
    When creating new events or types, it has to be added to the audit log filters
    specific to admin audit logs or study audit logs*/

export enum AuditCreationEventsEnum {
    Login = 'LOGIN',
    Logout = 'LOGOUT',
    Lockout = 'LOCKOUT',
    LockoutOverride = 'LOCKOUTOVERRIDE',
    PendoSync = 'PENDOSYNC',
    GuideAssigned = 'GUIDEASSIGNED',
    GuideComplete = 'COMPLETE',
    Certificate = 'CERTIFICATE',
    Create = 'CREATE',
    Update = 'UPDATE',
    View = 'VIEW',
    Delete = 'DELETE',
    Search = 'SEARCH',
    Download = 'DOWNLOAD',
    Upload = 'UPLOAD',
    EmailSent = 'EMAILSENT',
    Access = 'ACCESS',
    UsernameReminder = 'USERNAMEREMINDER',
    PasswordReset = 'PASSWORDRESET',
    GainedConnectivity = 'GAINEDCONNECTIVITY',
    LostConnectivity = 'LOSTCONNECTIVITY',
    QueryOpen = 'QUERYOPEN',
    QuerySiteResponded = 'QUERYSITERESPONDED',
    QueryClosed = 'QUERYCLOSED',
}
export enum AuditEntityIdEnum {
    User = 'USER',
    Device = 'DEVICE',
    Sponsor = 'SPONSOR',
    Customer = 'CUSTOMER',
    PrivacyPolicy = 'PRIVACY_POLICY',
    Site = 'SITE',
    Study = 'STUDY',
    StudyInfo = 'STUDY_INFO',
    StudyConfig = 'STUDY_CONFIG',
    Schedule = 'SCHEDULE',
    Visit = 'VISIT',
    Task = 'TASK',
    Notification = 'NOTIFICATION',
    PatientLibraryDocument = 'PATIENT_LIBRARY_DOCUMENT',
    Activity = 'ACTIVITY',
    LibraryActivity = 'LibraryActivity',
    Appointment = 'StudyInstanceAppointment',
    SubscriberTranslation = 'SubscriberTranslation',
    EventInst = 'SubjectStudyEventInstance',
    ActivityInst = 'SubjectStudyActivityInstance',
    Period = 'PERIOD',
    Translation = 'Translation',
    Region = 'Region',
    Training = 'Training',
    Report = 'Report',
    Query = 'QUERY',
    PatientChart = 'PatientChart',
    ActivityReview = 'ActivityReview',
    DataPoint = 'DataPoint',
    CalculatedValue = 'CalculatedValue',
}
