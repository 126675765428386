import {
    ActionType,
    MeetingStatus,
    Severity,
    useMeetingStatus,
    useNotificationDispatch,
} from 'amazon-chime-sdk-component-library-react';
import { useAppState } from 'common/telehealth/providers/AppStateProvider';
import { useEffect } from 'react';

const useMeetingEndRedirect = () => {
    const dispatch = useNotificationDispatch();
    const meetingStatus = useMeetingStatus();
    const { setStep } = useAppState();

    useEffect(() => {
        if (meetingStatus === MeetingStatus.Ended) {
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.INFO,
                    message: 'The meeting was ended by another attendee',
                    autoClose: true,
                    replaceAll: true,
                },
            });
            setStep('home');
        }
    }, [meetingStatus]);
};

export default useMeetingEndRedirect;
