import { gql } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { nonAuthClient } from 'common/graphql/client';
import { getCurrentLng, setLanguage } from 'common/i18n/i18n';
import { Form } from 'common/utils/validations';
import { CIDropDown } from 'common/viewcomp/forms/fields/select';
import { isEmpty, map } from 'lodash';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './change_language.scss';

const listTranslationFiles = gql`
    query listPortalTransl {
        listPortalTranslations(limit: 150, filter: { enableLoginInclusion: { eq: true }, isDeleted: { eq: 0 } }) {
            items {
                language
                displayName
            }
            totalItems
        }
    }
`;

const ChangeAppLanguage: FC<{}> = (): ReactElement => {
    const { t } = useTranslation('auth');
    const [languageSupported, setLanguageSupported] = useState([]);

    const changeLanguage = (e): void => {
        const language = e.target.value;
        const currentLang = getCurrentLng();
        localStorage.setItem('applicationLanguage', currentLang);
        setLanguage(language);
    };

    useEffect(() => {
        nonAuthClient
            .query({
                query: listTranslationFiles,
            })
            .then((response): void => {
                const respArr = response.data.listPortalTranslations?.items;
                const langArr = [
                    {
                        language: 'en-US',
                        displayName: 'English (United States)', // do not need to translalted as this should always display in english
                    },
                    ...respArr,
                ];
                setLanguageSupported(langArr);
            })
            .catch((): void => {});
    }, []);

    return (
        <Form
            onSubmit={changeLanguage}
            initialValues={{ displaylang: isEmpty(languageSupported) ? '' : 'en-US' }}
            render={({ handleSubmit }): ReactElement => {
                return (
                    <form onSubmit={handleSubmit} id="display_lang_form">
                        <div className="select_lang">
                            <CIDropDown
                                id="displaylang"
                                name="displaylang"
                                t={t}
                                labelI18NKey="login.language"
                                className="lang-dropdown"
                                onChange={changeLanguage}
                            >
                                {map(
                                    languageSupported,
                                    (lang): ReactElement => (
                                        <MenuItem value={lang.language} key={lang.language}>
                                            {`${lang.displayName}`}
                                        </MenuItem>
                                    )
                                )}
                            </CIDropDown>
                        </div>
                    </form>
                );
            }}
        />
    );
};

export default ChangeAppLanguage;
