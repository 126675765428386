import { ApolloClient, OperationVariables, gql } from '@apollo/client';
import Sentry from 'common/sentry/sentry';
import { DocumentNode, OperationDefinitionNode } from 'graphql';
import { Observable, ObservableInput, firstValueFrom, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CIError } from '../error/error_util';
import defaultClient, { OfflineContext } from './client';
import { ListResponse } from './types';

export type ProcessingState = null | 'PENDING' | 'FAILED' | 'NEW';

export interface AsyncObject<T> {
    processingState: ProcessingState;
    asyncObject?: T;
    error?: CIError;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLoading = (...asyncObjects: AsyncObject<any>[]): boolean => {
    for (const asyncObject of asyncObjects || []) {
        if (asyncObject?.processingState === 'NEW' || asyncObject?.processingState === 'PENDING') {
            return true;
        }
    }
    return false;
};

export function gqlQuery<T, P>(
    arg: P,
    query: string | DocumentNode,
    queryString: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    client: ApolloClient<any>,
    variables: object = {},
    pageLoad?: boolean,
    generateFields?: string[],
    offlineContext?: OfflineContext
): Observable<T> {
    const queryGql = gql`
        ${query}
    `;
    const transaction = Sentry.getCurrentHub().getScope().getTransaction();
    let span;
    if (pageLoad && transaction) {
        span = transaction.startChild({
            op: queryString || getQueryOperationName(queryGql) || 'unspecified',
            description: 'graphQL',
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promise = client
        .query({
            fetchPolicy: 'no-cache',
            query: queryGql,
            variables,
            context: {
                generateFields: generateFields?.length && generateFields,
                offlineContext: offlineContext,
            },
        })
        .then((apolloQueryResult): T => {
            const result = queryString ? apolloQueryResult.data[queryString] : apolloQueryResult.data;
            if (apolloQueryResult.data['isFromNative'] && result) {
                result['isFromNative'] = true;
            }
            return result as T;
        })
        .catch((err): T => {
            throw CIError.fromGraphQLError(err);
        })
        .finally(() => {
            span?.finish();
            transaction?.finish();
        });

    return from(promise);
}

const limit = 100;
function _gqlQueryListAll<T, P>(
    arg: P,
    query: string | DocumentNode,
    queryString: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    client: ApolloClient<any>,
    variables: object = {},
    nextToken: number,
    pageLoad?: boolean,
    generateFields?: string[],
    offlineContext?: OfflineContext
): Observable<T[]> {
    return gqlQuery<ListResponse<T>, P>(
        arg,
        query,
        queryString,
        client,
        {
            ...variables,
            nextToken: nextToken,
            limit: limit,
        },
        pageLoad,
        generateFields,
        offlineContext
    ).pipe(
        switchMap((listReponse) => {
            const items = listReponse.items;
            if (listReponse.totalItems > nextToken + limit) {
                return _gqlQueryListAll<T, P>(
                    arg,
                    query,
                    queryString,
                    client,
                    variables,
                    nextToken + limit,
                    pageLoad,
                    generateFields,
                    offlineContext
                ).pipe(map((nextPage) => items.concat(nextPage)));
            } else {
                return from([items]);
            }
        })
    );
}

/**
 * Make sure your query string includes the variables $limit and $nextToken
 */
export function gqlQueryListAll<T, P>(
    arg: P,
    query: string | DocumentNode,
    queryString: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    client: ApolloClient<any>,
    variables: object = {},
    pageLoad?: boolean,
    generateFields?: string[],
    offlineContext?: OfflineContext
): Observable<T[]> {
    return _gqlQueryListAll<T, P>(
        arg,
        query,
        queryString,
        client,
        variables,
        0,
        pageLoad,
        generateFields,
        offlineContext
    );
}

function getQueryOperationName(query: DocumentNode): string {
    const definition = query?.definitions?.[0] as OperationDefinitionNode;
    return definition?.name?.value;
}

export type SimpleQueryOptions = {
    variables?: OperationVariables;
    queryString?: string;
    watch?: boolean;
    generateFields?: string[];
    offlineContext?: OfflineContext;
    headers?: Record<string, string>;
};

export function gqlSimpleQuery<DataType>(
    query: string | DocumentNode,
    { queryString, variables, watch, generateFields, offlineContext, headers }: SimpleQueryOptions = {}
) {
    const loadQuery = watch ? defaultClient.watchQuery : defaultClient.query;

    const context =
        generateFields || offlineContext || headers
            ? { generateFields, offlineContext, specificHeaders: headers }
            : undefined;

    const observable = loadQuery({
        query: gql`
            ${query}
        `,
        variables,
        fetchPolicy: 'no-cache',
        context,
    });

    return from(observable as ObservableInput<{ data }>).pipe(
        map((result): DataType => (queryString ? result.data[queryString] : result.data))
    );
}

export function gqlSimpleQueryAsPromise<DataType>(
    query: string | DocumentNode,
    { queryString, variables, watch, generateFields, offlineContext, headers }: SimpleQueryOptions = {}
) {
    return firstValueFrom(
        gqlSimpleQuery<DataType>(query, {
            queryString,
            variables,
            watch,
            generateFields,
            offlineContext,
            headers,
        })
    );
}
