import { FC } from 'react';

const Thumbnail: FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="13" width="11" height="9" rx="1" fill="#fff" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 10.5996V3.5H21.5V16.5H13.2V18H22C22.5523 18 23 17.5523 23 17V3C23 2.44772 22.5523 2 22 2H5C4.44772 2 4 2.44772 4 3V10.5996H5.5Z"
            fill="white"
        />
        <path d="M13.5 7H17.5V11" stroke="white" />
        <path d="M17.25 7L12 13" stroke="white" />
    </svg>
);

export default Thumbnail;
