import { find, invokeMap, reject } from 'lodash';

import { OktaSession } from './oktaSession';
import { SsoSessionKeys } from './sso.types';

export class OktaUserPool {
    private storage: Storage;
    private _currentSession: { issuer: string; clientId: string; userId: string };

    public get currentSession(): OktaSession {
        return find(this.userSessions[this._currentSession?.issuer], {
            clientId: this._currentSession?.clientId,
            userId: this._currentSession?.userId,
        });
    }

    private userSessions: { [issuer: string]: OktaSession[] } = {};

    constructor(storage: Storage) {
        this.storage = storage;
        this.userSessions = {};
    }

    public removeIssuer = async (issuer: string) => {
        const userSessions: OktaSession[] = this.userSessions[issuer] || [];
        await Promise.all(invokeMap(userSessions, 'invalidate'));
        delete this.userSessions[issuer];
    };

    public addOktaSession = (sessionKeys: SsoSessionKeys): OktaSession => {
        const session = new OktaSession(this.storage, sessionKeys);
        const issuer = session.issuer;
        if (!this.userSessions[issuer]) {
            this.userSessions[issuer] = [];
        }
        this.userSessions[issuer].push(session);
        return session;
    };

    public removeSession = async (issuer?: string, clientId?: string, userId?: string) => {
        let session: OktaSession;
        let userSessions: OktaSession[] = [];
        if (issuer && clientId && userId) {
            userSessions = this.userSessions[issuer] || [];
            session = find(userSessions, { clientId, userId });
        } else {
            session = this.currentSession;
            userSessions = this.userSessions[session?.issuer];
        }

        if (session) {
            if (this.currentSession?.userPoolKey === session.userPoolKey) {
                this._currentSession = null;
            }
            this.userSessions[issuer] = reject(userSessions, { clientId: session.clientId, userId: session.userId });
            session.invalidate();
        }
    };

    public getSession = (issuer: string, clientId: string, userId: string): OktaSession => {
        const userSessions: OktaSession[] = this.userSessions[issuer] || [];
        return find(userSessions, { clientId, userId });
    };

    public setCurrentSession = (issuer: string, clientId: string, userId: string): void => {
        this._currentSession = { issuer, clientId, userId };
    };

    public setSessionAsCurrent = (session: OktaSession): void => {
        this._currentSession = { issuer: session.issuer, clientId: session.clientId, userId: session.userId };
    };
}

const oktaUserPool = new OktaUserPool(localStorage);
export const getOktaUserPool = (): OktaUserPool => oktaUserPool;
