import { forEach } from 'lodash';
import { Component, ComponentType, createContext, ReactElement } from 'react';
import { AuthUser } from '../auth/auth_context';

export interface Analytics {
    initialize();

    identifyUser(user: AuthUser);
    anonymousUser(pendoObj: string);
    clearSession();

    track(...e: any);
}

export class CIAnalytics {
    private static __instance: CIAnalytics = new CIAnalytics();

    public static getInstance(): CIAnalytics {
        return CIAnalytics.__instance;
    }

    private providers: Analytics[] = [];

    public initialize(): void {
        forEach(this.providers, (provider): void => provider.initialize());
    }

    public registerProvider(provider: Analytics): void {
        this.providers.push(provider);
    }

    public deregisterProvider(provider: Analytics): void {
        for (let i = 0; i < this.providers.length; i++) {
            if (this.providers[i] === provider) {
                delete this.providers[i];
                break;
            }
        }
    }

    public identifyUser(user: AuthUser): void {
        forEach(this.providers, (provider): void => provider.identifyUser(user));
    }
    public anonymousUser(pendoObj: string): void {
        forEach(this.providers, (provider): void => provider.anonymousUser(pendoObj));
    }

    public clearSession(): void {
        forEach(this.providers, (provider): void => provider.clearSession());
    }

    public trackEvent(...e): void {
        forEach(this.providers, (provider): void => provider.track(e));
    }
}

export const AnalyticsContext = createContext(CIAnalytics);

export default function withAnalytics(WrappedComponent): ComponentType {
    return class extends Component {
        public render(): ReactElement {
            return (
                <AnalyticsContext.Provider value={CIAnalytics}>
                    <WrappedComponent></WrappedComponent>
                </AnalyticsContext.Provider>
            );
        }
    };
}
