import styled from 'styled-components';

export const StyledList = styled.dl`
    font-size: 1rem;
    text-align: center;
    margin-top: 16px;

    dt {
        display: inline-block;
        margin-bottom: 0.75rem;
        margin-right: 0.5rem;

        .rtl & {
            margin-right: 0;
            margin-left: 0.5rem;
        }
    }
`;
