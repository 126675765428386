import {
    AudioInputControl,
    AudioInputVFControl,
    AudioOutputControl,
    ControlBar,
    ControlBarButton,
    Dots,
    useUserActivityState,
    VideoInputControl,
} from 'amazon-chime-sdk-component-library-react';
import VideoInputTransformControl from 'common/telehealth/components/MeetingControls/VideoInputTransformControl';
import ThumbnailControl from 'common/telehealth/containers/ThumbnailControl';
import { useAppState } from 'common/telehealth/providers/AppStateProvider';
import { useNavigation } from 'common/telehealth/providers/NavigationProvider';
import { VideoFiltersCpuUtilization } from 'common/telehealth/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EndMeetingControl from '../EndMeetingControl';
import { StyledControls } from './Styled';

const MeetingControls: FC = () => {
    const { t } = useTranslation('global');
    const { toggleNavbar, closeRoster, showRoster } = useNavigation();
    const { isUserActive } = useUserActivityState();
    const { isWebAudioEnabled, videoTransformCpuUtilization } = useAppState();
    const videoTransformsEnabled = videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;

    const handleToggle = (): void => {
        if (showRoster) {
            closeRoster();
        }
        toggleNavbar();
    };

    return (
        <StyledControls className="controls" active={!!isUserActive}>
            <ControlBar className="controls-menu" layout="undocked-horizontal" showLabels>
                <ControlBarButton
                    className="mobile-toggle"
                    icon={<Dots />}
                    onClick={handleToggle}
                    label={t('telehealth.controls.menu')}
                />
                {isWebAudioEnabled ? (
                    <AudioInputVFControl
                        muteLabel={t('telehealth.controls.mute')}
                        unmuteLabel={t('telehealth.controls.unmute')}
                    />
                ) : (
                    <AudioInputControl
                        muteLabel={t('telehealth.controls.mute')}
                        unmuteLabel={t('telehealth.controls.unmute')}
                    />
                )}
                {videoTransformsEnabled ? (
                    <VideoInputTransformControl
                        label={t('telehealth.controls.video')}
                        backgroundBlurLabel={t('telehealth.controls.blur')}
                    />
                ) : (
                    <VideoInputControl />
                )}
                <AudioOutputControl label={t('telehealth.controls.speaker')} />
                <ThumbnailControl />
                <EndMeetingControl />
            </ControlBar>
        </StyledControls>
    );
};

export default MeetingControls;
