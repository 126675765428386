import { Capacitor } from '@capacitor/core';
import withTranslate from 'common/i18n/i18n';
import { NativeDeviceInitializer } from 'common/native-app-support/device-initializer/native.device.initializer';
import logoIcon from 'img/logos/logo.svg';
import { flow } from 'lodash';
import { ReactElement, useState } from 'react';
import ChangeAppLanguage from '../change_language';
import './authLayout.page.scss';

export const AuthLayout = ({ t, children }): ReactElement => {
    const [deviceID, setDeviceID] = useState(null);

    if (Capacitor.isNativePlatform()) {
        NativeDeviceInitializer.getDeviceSerialNumber().then((id: string) => {
            setDeviceID(id);
        });
    }

    return (
        <div id="auth_layout">
            {deviceID && <h6 className="device_id">{deviceID}</h6>}

            <div id="auth_layout_page" className="page">
                <div className="main-content">
                    <div className="logo">
                        <img alt={t('login.clinical_logo_alt')} src={logoIcon} />
                    </div>
                    <div id="auth_layout_box">
                        <div className="auth-layout-box">
                            <div className="auth-layout-box-content" role="main">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChangeAppLanguage />
        </div>
    );
};

export const WrappedAuthLayout = flow([withTranslate('auth')])(AuthLayout);

export const withAuthLayout = (WrappedComponent) => {
    return (props): ReactElement => {
        return (
            <div>
                <WrappedAuthLayout>
                    <WrappedComponent {...props} />
                </WrappedAuthLayout>
            </div>
        );
    };
};
