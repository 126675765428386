import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/study/StudyListPage')),
        permissions: ['Study_Read', 'Study_Create', 'Study_Edit', 'Study_Delete'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.ClinicalTrialLead,
            RoleVariantEnum.BlindedStudyManager,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/study',
        block: true,
    },
    {
        component: lazy(() => import('module/study/StudyListPage')),
        permissions: ['Study_Read', 'Study_Create', 'Study_Edit', 'Study_Delete'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/customer/:customerId/study',
    },
];
