import { styled } from '@mui/material';

export const HeadingText = styled('span')({
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '29px',
});

export const SubHeadingText = styled('span')(({ theme }) => ({
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '24px',
    color: theme.ciPalette.text.lightBlack,
}));

export const BodyText = styled('span')({
    fontSize: 14,
    lineHeight: '22px',
});

export const SubText = styled('span')({
    fontSize: 12,
    lineHeight: '19px',
});

export const GreyedOutText = styled('span')(({ theme }) => ({
    color: theme.ciPalette.lightGrey.inactive,
}));

export const DarkGreyText = styled('span')(({ theme }) => ({
    color: theme.ciPalette.lightGrey.text,
}));

export const BlackText = styled('span')(({ theme }) => ({
    color: theme.ciPalette.text.fullBlack,
}));

export const ErrorText = styled('span')(({ theme }) => ({
    color: theme.ciPalette.error.ciErrorRed,
}));

export const MuiErrorText = styled('span')(({ theme }) => ({
    color: theme.ciPalette.error.muiErrorRed,
}));

export const BoldText = styled('span')({
    fontWeight: 500,
});

export const ItalicText = styled('span')({
    fontStyle: 'italic',
});
