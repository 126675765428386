import { Dialog, DialogContent, DialogTitle, Icon } from '@mui/material';
import { Close as CloseIcon, ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from '@mui/icons-material';
import { ControlBarButton, Phone } from 'amazon-chime-sdk-component-library-react';
import { CISecondaryButton, CITertiaryButton } from 'common/viewcomp/forms/buttons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../providers/AppStateProvider';

const EndMeetingControl: FC = () => {
    const { t } = useTranslation('global');

    const [showModal, setShowModal] = useState(false);
    const toggleModal = (): void => setShowModal(!showModal);
    const { isAdmin, close } = useAppState();

    const leaveMeeting = async (): Promise<void> => {
        await close();
    };

    const endMeetingForAll = async (): Promise<void> => {
        await close();
    };

    return (
        <>
            <ControlBarButton icon={<Phone />} onClick={toggleModal} label={t('telehealth.close')} />
            {showModal && (
                <Dialog open id="end_meeting_dialog">
                    <DialogTitle id="end_meeting_dialog__title">
                        {isAdmin && (
                            <Icon className="warning-icon">
                                <ErrorOutlineOutlinedIcon fontSize="large" />
                            </Icon>
                        )}
                        <p className="title-text">
                            {t(isAdmin ? 'telehealth.end_meeting.title' : 'telehealth.end_meeting.patient_title')}
                        </p>
                        <CloseIcon onClick={toggleModal} />
                    </DialogTitle>
                    <DialogContent>
                        <p className="message">{t('telehealth.end_meeting.description')}</p>
                        <div className="button_group">
                            {!isAdmin && (
                                <CITertiaryButton onClick={toggleModal} id="cancel">
                                    {t('telehealth.end_meeting.stay')}
                                </CITertiaryButton>
                            )}
                            <CISecondaryButton onClick={leaveMeeting} id={isAdmin ? 'end' : 'end-patient'}>
                                {t(isAdmin ? 'telehealth.end_meeting.leave' : 'telehealth.end_meeting.patient_leave')}
                            </CISecondaryButton>
                            {isAdmin && (
                                <CISecondaryButton onClick={endMeetingForAll} id="end-all">
                                    {t('telehealth.end_meeting.leave_all')}
                                </CISecondaryButton>
                            )}
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default EndMeetingControl;
