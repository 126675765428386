import axios from 'axios';

const client = axios.create({
    /*
     * This is being setup to expect that we don't have any REST endpoints that work on site-specific data (which would
     * need to be routed to a specific instance of API Gateway to get the subject data to/from the correct DynamoDB instance
     */
    baseURL: process.env.REACT_APP_AWS_REST_ENDPOINT,
    timeout: 20000, //TODO: May need to tune this depending on the prod deployment architecture.  This is assuming there will be a local instance for all test sites, which may or may not be true
    headers: {
        accept: 'application/json',
        //TODO: Should inject the UI session id into the request so it's available for application logging allowing user activity to be correlated
    },
    maxContentLength: 200000,
    transformRequest: [
        // this is needed to remove default axios headers, so it doesnt conflict with IE11/Edge
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data, headers): any => {
            delete headers.put;
            delete headers.post;
            return data;
        },
    ],
});

export default client;

export const latencyLookupClient = axios.create({
    /*
     * This is being setup to expect that we don't have any REST endpoints that work on site-specific data (which would
     * need to be routed to a specific instance of API Gateway to get the subject data to/from the correct DynamoDB instance
     */
    baseURL: process.env.REACT_APP_AWS_APPSYNC_ROUTING_ENDPOINT,
    timeout: 2000,
    headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'lx-version': '2.5',
    },
    maxContentLength: 200000,
});

export const pendoClient = axios.create({
    baseURL: 'https://app.pendo.io/api/v1',
    timeout: 20000,
    headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'x-pendo-integration-key': process.env.REACT_APP_PENO_APPSYNC_APIKEY,
    },
    transformRequest: [
        (data, headers): object => {
            delete headers.put;
            delete headers.post;
            return data;
        },
    ],
});
