import { ApolloProvider } from '@apollo/client';
import '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import ApolloClient from 'common/graphql/client';
import { logNativeAppDetails } from 'common/utils/native_app_details';
import { logWebAppDetails } from 'common/utils/web_app_details';
import { CITheme } from 'common/viewcomp/general/CITheme';
import { CISnackBar } from 'common/viewcomp/general/ci_pagestatus';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import App from './app';
import { DndProvider } from 'react-dnd';
import { Html5AndTouchBackend } from 'common/viewcomp/layout/dnd-multi-backend';

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_TEST_ACCESSIBILITY === 'true') {
    import('@axe-core/react').then(({ default: axe }): void => {
        axe(React, ReactDOM, 1000);
    });
}

if (Capacitor.isNativePlatform()) {
    logNativeAppDetails();
} else {
    logWebAppDetails();
}

/* eslint-enable */
const WithProvider = (): ReactElement => (
    <CITheme>
        <Provider store={store}>
            <CISnackBar />
            <ApolloProvider client={ApolloClient}>
                {/* TODO: REMOVE COMMENTED TAGS UNTIL MAJOR PACKAGES HAVE BEEN UPGRADED */}
                {/* <React.StrictMode> */}
                <DndProvider backend={Html5AndTouchBackend}>
                    <App />
                </DndProvider>
                {/* </React.StrictMode> */}
            </ApolloProvider>
        </Provider>
    </CITheme>
);

if (document.getElementById('root')) {
    ReactDOM.render(<WithProvider />, document.getElementById('root'));
}
