import { Logger, LogLevel, POSTLogger } from 'amazon-chime-sdk-js';

export type MeetingFeatures = {
    Audio: { [key: string]: string };
};

export type CreateMeetingResponse = {
    MeetingFeatures: MeetingFeatures;
};

export type JoinMeetingInfo = {
    Meeting: CreateMeetingResponse;
    Attendee: string;
};

export type FormattedDeviceType = {
    deviceId: string;
    label: string;
};

export type DeviceType = MediaDeviceInfo | FormattedDeviceType;

export enum MeetingMode {
    Spectator,
    Attendee,
}

export enum Layout {
    Gallery,
    Featured,
}

// Different CPU Utilizations percentage options for initializing background blur and replacement processors
export const VideoFiltersCpuUtilization = {
    Disabled: '0',
    CPU10Percent: '10',
    CPU20Percent: '20',
    CPU40Percent: '40',
};

// Video Transform Options
export const VideoTransformOptions = {
    None: 'None',
    Blur: 'Background Blur',
    Replacement: 'Background Replacement',
};

export const DraggableTelehealth = {
    WINDOW: 'TelehealthWindow',
};

export const SDK_LOG_LEVELS = {
    'debug': LogLevel.DEBUG,
    'info': LogLevel.INFO,
    'warn': LogLevel.WARN,
    'error': LogLevel.ERROR,
    'off': LogLevel.OFF,
};

export interface TelehealthBasicProps {
    id?: string;
    name?: string;
    isAdmin?: boolean;
}

interface MediaPlacement {
    AudioHostUrl: string;
    AudioFallbackUrl: string;
    ScreenDataUrl: string;
    ScreenSharingUrl: string;
    ScreenViewingUrl: string;
    SignalingUrl: string;
    TurnControlUrl: string;
    EventIngestionUrl: string;
}

export interface Meeting {
    MeetingId: string;
    ExternalMeetingId: string;
    MediaPlacement: MediaPlacement;
    MediaRegion: string;
}

interface Attendee {
    ExternalUserId: string;
    AttendeeId: string;
    JoinToken: string;
}

export interface JoinInfo {
    Title: string;
    Meeting: Meeting;
    Attendee: Attendee;
}

export type MeetingConfig = {
    simulcastEnabled: boolean;
    logger: Logger;
    postLogger?: POSTLogger; // Keep track of POSTLogger to update meeting metadata while joining a meeting.
};

export interface GridState {
    layout: Layout;
    isZoomed: boolean;
    threshold: number;
}

export interface AttendeeState {
    attendeeId: string;
    name: string;
    videoEnabled: boolean;
    bandwidthConstrained: boolean;
}

export interface VideoSourceState {
    cameraSources: string[];
    activeSpeakersWithCameraSource: string[];
    contentShareId: string | null;
    hasLocalVideo: boolean;
    hasLocalContentSharing: boolean;
}
