import { darkTheme, GlobalStyles, NotificationProvider } from 'amazon-chime-sdk-component-library-react';
import { TelehealthBasicProps } from 'common/telehealth/types';
import { memo, ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import MeetingProviderWrapper from './containers/MeetingProviderWrapper';
import Notifications from './containers/Notifications';
import { AppStateProvider } from './providers/AppStateProvider';
import ErrorProvider from './providers/ErrorProvider';
import './style.scss';

interface TelehealthProps extends TelehealthBasicProps {
    closeParent: () => void;
}

function Telehealth(props: TelehealthProps): ReactElement {
    return (
        <AppStateProvider close={props.closeParent}>
            <ThemeProvider
                theme={{
                    ...darkTheme,
                    globalStyle: {
                        ...darkTheme.globalStyle,
                        bgd: 'transparent',
                    },
                    roster: {
                        ...darkTheme.roster,
                        bgd: '#1e376c',
                    },
                    controlBar: {
                        ...darkTheme.controlBar,
                        bgd: '#1e376c',
                    },
                    videoGrid: {
                        ...darkTheme.videoGrid,
                        bgd: '#395186',
                    },
                    navbar: {
                        ...darkTheme.navbar,
                        bgd: '#1e376c',
                    },
                }}
            >
                <GlobalStyles />
                <NotificationProvider>
                    <Notifications />
                    <ErrorProvider>
                        <MeetingProviderWrapper id={props.id} name={props.name} isAdmin={props.isAdmin} />
                    </ErrorProvider>
                </NotificationProvider>
            </ThemeProvider>
        </AppStateProvider>
    );
}

const TelehealthMemo = memo(
    (props: TelehealthProps) => <Telehealth {...props} />,
    (prevProps: TelehealthProps, nextProps: TelehealthProps): boolean => {
        if (prevProps.id !== nextProps.id) {
            return false;
        }
        return true;
    }
);

export default TelehealthMemo;
