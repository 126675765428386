import {
    Button,
    ButtonGroup,
    ButtonProps,
    Grid,
    IconButton,
    IconButtonProps,
    lighten,
    styled,
    SvgIcon,
    Tooltip,
} from '@mui/material';
import classNames from 'classnames';
import { isNull, isString, map } from 'lodash';
import {
    cloneElement,
    ElementType,
    FC,
    memo,
    PropsWithChildren,
    ReactElement,
    Ref,
    useLayoutEffect,
    useState,
} from 'react';
import { LoadingOverlay } from '../../utils/loading';
import './buttons.scss';
import './ci_button_group_row.scss';
import './ci_icon_button_group.scss';

export interface CIButtonProps extends PropsWithChildren<ButtonProps> {
    isLoading?: boolean;
    disabled?: boolean;
    buttonRef?: Ref<HTMLButtonElement>;
    onClick?: (e: any) => void;
}

export const CIButtonGroup = ({ children }): ReactElement => {
    return (
        <div className="button_group">
            <Grid container spacing={2}>
                {children}
            </Grid>
        </div>
    );
};

export const CIButton: FC<CIButtonProps> = ({ children, isLoading, buttonRef, ...props }): ReactElement => {
    const className = `ci-button ${props.className || ''}`;
    const disabled = props.disabled || isLoading;
    return (
        <>
            <Button
                {...props}
                disabled={disabled}
                key={`${disabled}`} // Workaround until https://github.com/mui-org/material-ui/issues/26251 is fixed
                className={className}
                ref={buttonRef}
            >
                <LoadingOverlay isLoading={isLoading}>{children}</LoadingOverlay>
            </Button>
        </>
    );
};

export const CIPrimaryButton = (props: CIButtonProps): ReactElement => (
    <CIButton {...props} variant="contained" color="primary" />
);

export const CISecondaryButton = (props: CIButtonProps): ReactElement => (
    <CIButton {...props} variant="contained" color="secondary" />
);

export const CITertiaryButton = (props: CIButtonProps): ReactElement => <CIButton {...props} variant="outlined" />;

export const CIDangerButton = (props: CIButtonProps): ReactElement => {
    return <DangerButton variant="contained" {...props} />;
};

const DangerButton = styled(CIButton)(({ theme }) => ({
    '&.MuiButton-contained.MuiButton-containedPrimary': {
        backgroundColor: theme.ciPalette.error.muiErrorRed,

        '&:hover': {
            backgroundColor: lighten(theme.ciPalette.error.muiErrorRed, 0.15),
        },
    },
}));

export const CIBorderlessButton = (props): ReactElement => <CIButton {...props} />;

interface CIIconButtonProps extends IconButtonProps {
    tooltip?: string;
    alt?: string;
    icon: string | ElementType;
}
const _CIIconButton = ({ icon, alt, tooltip, ...props }: CIIconButtonProps): ReactElement => {
    const _tooltip = tooltip || '';
    const [open, setOpen] = useState(false);

    const showTooltip = (): void => setOpen(true);
    const hideTooltip = (): void => setOpen(false);

    return (
        <span onMouseOver={showTooltip} onMouseOut={hideTooltip} onMouseDown={hideTooltip}>
            <Tooltip className="ci-icon-button" title={_tooltip} open={open} arrow>
                <IconButton aria-label={_tooltip} size="large" {...props}>
                    {isString(icon) ? <img src={icon} alt={alt} /> : <SvgIcon component={icon} titleAccess={alt} />}
                </IconButton>
            </Tooltip>
        </span>
    );
};
export const CIIconButton = memo(_CIIconButton);

interface CIIconButonGroupProps {
    showSelected?: boolean;
    selected?: number;
    children: ReactElement[];
}
export const CIIconButtonGroup = ({ showSelected, selected, children }: CIIconButonGroupProps): ReactElement => {
    const [_selected, setSelected] = useState(isNull(selected) ? -1 : selected);

    return (
        <div
            className={classNames({
                'ci-icon-group-wrapper': true,
                'ci-icon-group-show-selected': showSelected,
            })}
        >
            {map(
                children,
                (child, index): ReactElement => (
                    <div
                        key={index}
                        onClick={(): void => setSelected(index)}
                        className={classNames({
                            'ci-icon-group-child': true,
                            'ci-icon-group-child-selected': index === _selected,
                        })}
                    >
                        {child}
                    </div>
                )
            )}
        </div>
    );
};

interface CIButtonGroupRowProps {
    showSelected?: boolean;
    selected?: number;
    children: ReactElement[];
    keepStateExternal?: boolean;
}
export const CIButtonGroupRow = ({
    showSelected,
    selected,
    children,
    keepStateExternal,
}: CIButtonGroupRowProps): ReactElement => {
    const [_selected, setSelected] = useState(selected === undefined ? -1 : selected);
    useLayoutEffect(() => {
        setSelected(selected);
    }, [selected]);

    return (
        <ButtonGroup
            aria-label="outlined button group"
            className={classNames({
                'ci-button-group-row': true,
                'ci-button-group-show-selected': showSelected,
            })}
        >
            {map(children, (child, index): ReactElement => {
                let className = child.props.className;
                if (index === _selected) {
                    className += ' ci-button-group-child-selected';
                }
                const nativeClick = child.props.onClick;
                const newClick = (e): void => {
                    if (!keepStateExternal) {
                        setSelected(index);
                    }
                    nativeClick(e);
                };
                return cloneElement(child, { key: index, className, onClick: newClick });
            })}
        </ButtonGroup>
    );
};
