import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/devices/DevicesPage')),
        permissions: [
            'Device_Read',
            'Device_Create',
            'Device_Edit',
            'Device_Delete',
            'DeviceLog_Read',
            'DeviceLog_Create',
        ],
        path: '/devices/:tab',
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.AdminReadOnly,
        ],
    },
];
