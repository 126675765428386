import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/activitylibrary/ActivityLibraryList')),
        permissions: ['Study_Library_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/',
    },
];
