import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { RouteType } from 'common/types/route';
import { preloadLazyComponent } from 'common/utils/loading';

export const routes: RouteType[] = [
    {
        component: preloadLazyComponent(() => import('module/activestudysite/ActiveStudySitePage')),
        permissions: [
            'StudyInstanceAppointment_Create',
            'Patient_Create',
            'Patient_Read',
            'StudyInstanceAppointment_Read',
            'StudyInstanceAppointment_Edit',
            'Patient_Edit',
        ],
        path: '/activestudysite',
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.ClinicalTrialLead,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
            RoleVariantEnum.AdminReadOnly,
        ],
    },
];
