import { Network } from '@capacitor/network';
import { Collapse, Typography } from '@mui/material';
import classNames from 'classnames';
import { AuthState } from 'common/auth/auth_context';
import withTranslate from 'common/i18n/i18n';
import { AuditCreationEventsEnum, AuditEntityIdEnum } from 'common/log/auditevents';
import { CIAuditLogger } from 'common/log/ci.auditlogger';
import { OfflineManager } from 'common/native-app-support/native.offline.manager';
import { NetworkActions, NetworkState, NetworkStatus } from 'common/network-status/network_status_context';
import { ReactComponent as Connected } from 'img/native/connected.svg';
import { ReactComponent as Offline } from 'img/native/offline.svg';
import { flow } from 'lodash';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { connect, useStore } from 'react-redux';
import './ci_network_banner.scss';

interface CINetworkBannerProps extends WithTranslation {
    setOffline: () => {};
    setOnline: () => {};
    network: NetworkState;
}

function CINetworkBanner({ t, setOffline, setOnline, network }: CINetworkBannerProps): ReactElement {
    const store = useStore<{ auth: AuthState }>();
    // TODO verify for switch user case flow
    const { auth } = store.getState();

    let userPk = null;
    if (auth && auth.activeUser) {
        userPk = auth.activeUser?.pk;
    }
    const [visible, setVisible] = useState<boolean>(false);
    const activityRef = useRef(null);

    const scrollEventListener = (): void => {
        const networkBanner = document.getElementById('networkBanner');
        const networkBannerPlacholder = document.getElementById('networkBannerPlacholder');

        if (window.pageYOffset > 0) {
            networkBannerPlacholder.style.height = `${networkBanner.offsetHeight}px`;
            networkBanner.classList.add('network-banner-container--fixed');
        } else {
            networkBannerPlacholder.style.height = '0px';
            networkBanner.classList.remove('network-banner-container--fixed');
        }
    };

    const networkConnectivityLogs = (networkStatus: unknown, message: string, userPk: string) => {
        CIAuditLogger.getInstance().log(networkStatus, message, null, {
            relatedEntityName: AuditEntityIdEnum.User,
            relatedEntityId: userPk,
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollEventListener, false);
        return (): void => {
            window.removeEventListener('scroll', scrollEventListener);
        };
    }, []);

    useEffect((): void => {
        Network.getStatus().then((status) => {
            if (!status.connected) {
                setOffline();
            }
        });
    }, []);

    useEffect(() => {
        const listener = Network.addListener('networkStatusChange', (status): void => {
            OfflineManager.getInstance().updateConnectionStatus(status);
            if (status.connected) {
                setOnline();
                if (userPk) {
                    networkConnectivityLogs(
                        AuditCreationEventsEnum.GainedConnectivity,
                        'Tablet Site Application gained network connectivity',
                        userPk
                    );
                }
            } else {
                setOffline();
                if (userPk) {
                    networkConnectivityLogs(
                        AuditCreationEventsEnum.LostConnectivity,
                        'Tablet Site Application lost network connectivity',
                        userPk
                    );
                }
            }
        });
        return (): void => {
            listener.remove();
        };
    }, []);

    useEffect(() => {
        setVisible(true);
        const timeout = setTimeout(() => {
            setVisible(false);
        }, 3000);

        return (): void => {
            clearTimeout(timeout);
        };
    }, [network.status]);

    return (
        <>
            <div>
                <div id="networkBannerPlacholder" />
                <div id="networkBanner">
                    <Collapse timeout={1500} in={visible}>
                        <div
                            ref={activityRef}
                            className={classNames('network-banner', 'network-banner-container', {
                                'network-banner-container--state-offline': network.status === NetworkStatus.OFFLINE,
                                'network-banner-container--state-online': network.status === NetworkStatus.ONLINE,
                            })}
                        >
                            {network.status === NetworkStatus.ONLINE ? <Connected /> : <Offline />}

                            <Typography display="inline" variant="subtitle1">
                                {network.status === NetworkStatus.OFFLINE
                                    ? t('network_status.offline')
                                    : t('network_status.online')}
                            </Typography>
                        </div>
                    </Collapse>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ network }): object => ({ network });

const mapDispatchToProps = { ...NetworkActions };

export default flow([withTranslate('global')])(connect(mapStateToProps, mapDispatchToProps)(CINetworkBanner));
