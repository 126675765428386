/* eslint-disable no-console */
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { formattedConsoleLog } from './web_app_details';

interface NativeAppDetails {
    appVersion: string;
    gitVersion: string;
    branch: string;
    tag: string;
    deviceModel: string;
}

export default async function getNativeAppDetails(): Promise<NativeAppDetails> {
    return {
        appVersion: (await App.getInfo()).version,
        gitVersion: process.env.REACT_APP_GIT_COMMIT_HASH || '',
        branch: process.env.REACT_APP_GIT_BRANCH_NAME || '',
        tag: 'N/A',
        deviceModel: (await Device.getInfo()).model,
    };
}

export const logNativeAppDetails = async (): Promise<void> => {
    const { appVersion, gitVersion, branch, tag } = await getNativeAppDetails();
    console.group('Lunexis Native App Details');
    formattedConsoleLog('App version', appVersion);
    formattedConsoleLog('Git version', gitVersion);
    formattedConsoleLog('Branch', branch);
    formattedConsoleLog('Tag', tag);
    console.groupEnd();
};
