import { AuthUser } from 'common/auth/auth_context';
import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { RouteType } from 'common/types/route';
import { includes, some } from 'lodash';
import { isInPath } from './common.utils';

export const SPONSOR_DASHBOARD_ROLES: (BaseRoles | RoleVariantEnum)[] = [
    RoleVariantEnum.Monitor,
    RoleVariantEnum.DataManager,
];
const SPONSOR_DASHBOARD_PERMISSIONS = [];

export const PATIENT_LISTING_ROUTE: RouteType = {
    permissions: SPONSOR_DASHBOARD_PERMISSIONS,
    path: '/patientlisting',
    role: SPONSOR_DASHBOARD_ROLES,
    studyOnlyRequired: true,
};

export const SITE_LISTING_ROUTE: RouteType = {
    permissions: SPONSOR_DASHBOARD_PERMISSIONS,
    path: '/sitelisting',
    role: SPONSOR_DASHBOARD_ROLES,
    studyOnlyRequired: true,
};

export const SPONSOR_HOME_PATH = PATIENT_LISTING_ROUTE.path;
export const SPONSOR_PATHS_WITH_ACTIVE_SITE_SELECTION = ['/reports', '/siteDashboard', '/chart'];

export const isSponsorUser = (authUser: AuthUser): boolean =>
    includes(SPONSOR_DASHBOARD_ROLES, authUser?.roleVClaim?.sk);

// Sponsor dashboard users (Monitors and others), need to select only study not a site for most pages. Some pages
// require a site to function though, so we keep a list of them here
export const isPathWithActiveSiteSelectionForSponsor = (path: string) =>
    some(SPONSOR_PATHS_WITH_ACTIVE_SITE_SELECTION, (pathWithActiveSiteSelection) => {
        return isInPath(path, pathWithActiveSiteSelection);
    });
