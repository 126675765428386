import Bowser from 'bowser';
import { getCurrentLng } from 'common/i18n/i18n';
import { ActiveStudySite, AuthUser } from '../auth/auth_context';
import { Analytics } from './analytics.provider';

export default class PendoAnalytics implements Analytics {
    public initialize(): void {}
    public identifyUser(user: AuthUser): void {
        const { os, platform } = Bowser.parse(window.navigator.userAgent);
        const { pk, email, username, subType, userStudies, roleBClaim, roleVClaim, createdAt, type } = user;
        const activeStudySite: ActiveStudySite = JSON.parse(sessionStorage.getItem('activeStudySite'));
        (window as any).pendo.identify({
            visitor: {
                id: pk,
                email,
                userCreation: createdAt,
                username,
                userType: type,
                subType,
                language: getCurrentLng(),
                preferredLanguage: getCurrentLng(),
                studyId: activeStudySite?.activeStudy?.studyId || userStudies[0]?.studyId,
                siteId: activeStudySite?.activeSite?.siteId || userStudies[0]?.userStudySites[0]?.siteId,
                baseRole: roleBClaim?.data,
                roleVariant: roleVClaim?.name,
                device: platform.type === 'desktop' ? 'Web' : platform.type,
                operatingSystem: os.name,
                osVersion: os.version,
            },

            account: {
                id: user.subscriberPk,
                // name:         // Optional
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            },
        });
    }
    public anonymousUser(pendoObj): void {
        const { os, platform } = Bowser.parse(window.navigator.userAgent);
        (window as any).pendo[pendoObj]({
            visitor: {
                language: getCurrentLng(),
                preferredLanguage: getCurrentLng(),
                device: platform.type === 'desktop' ? 'Web' : platform.type,
                operatingSystem: os.name,
                osVersion: os.version,
            },

            account: {
                //
            },
        });
    }

    public clearSession() {
        (window as any).pendo.clearSession();
    }

    public track(): void {}
}
