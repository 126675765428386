import { RoleVariantEnum, UserTypes } from 'common/graphql/types';
import { OfflineManager } from 'common/native-app-support/native.offline.manager';
import { RouteType } from 'common/types/route';
import { ECONSENT_REGEX, STUDY_ID_REGEX, STUDY_VERSION_URL_REGEX } from 'common/utils/constants';
import { getCookie, OrionCookies } from 'common/utils/cookies';
import { includes, intersection } from 'lodash';
import { FC, ReactElement, ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { RootState } from 'store';
import StudyRoute from './auth_study_restrict';

const AccessibleRoute = ({ component: Component, ...props }): ReactElement => {
    const checkStudyDeleted =
        !STUDY_VERSION_URL_REGEX.test(props.location.pathname) && STUDY_ID_REGEX.test(props.location.pathname);

    if (checkStudyDeleted && !ECONSENT_REGEX.test(props.location.pathname)) {
        return (
            <StudyRoute component={Component} studyId={props.location.pathname.match(STUDY_ID_REGEX)[0]} {...props} />
        );
    }
    return <Component {...props} />;
};

const isUserLoggedIn = (authContext): boolean =>
    authContext != null && authContext.activeUser != null && authContext.switchingUser !== null;

const hasPermission = (userPermissions, requiredPermissions): boolean =>
    !requiredPermissions || !requiredPermissions.length || intersection(requiredPermissions, userPermissions).length
        ? true
        : false;

const hasRole = (userRole, requiredRole): boolean => includes(requiredRole, userRole);

interface Props {
    path: string;
    exact?: boolean;
    component: ReactNode;
    role: string[];
    permissions: string[];
    permsRedirect?: string;
    accessRoute?: RouteType;
    history?: History;
}

export const AuthenticatedRoute: FC<Props> = ({
    component,
    role = [],
    permissions = [],
    permsRedirect = '/dashboard',
    accessRoute,
    ...rest
}): ReactElement => {
    const auth = useSelector((state: RootState) => state.auth);

    const getComponentOrRedirect = useCallback(
        (props, accessRoute: RouteType): ReactElement => {
            const isLoggedIn = isUserLoggedIn(auth);

            let roleId =
                (auth.activeUser?.roleBClaim?.sk && auth.activeUser?.role === RoleVariantEnum.SystemAdminstrator
                    ? RoleVariantEnum.SystemAdminstrator // Since base role ID of sysadmin and subadmin are the same, differentiate them with role attribute
                    : auth.activeUser?.roleBClaim?.sk) || null;
            if (auth.activeUser?.roleVClaim?.sk) {
                roleId = auth.activeUser?.roleVClaim?.sk;
            }

            let hasRequiredPerms = isLoggedIn ? hasRole(roleId, role) : false;
            hasRequiredPerms =
                isLoggedIn && !hasRequiredPerms && !role
                    ? hasPermission(auth.activeUser.permissions, permissions)
                    : hasRequiredPerms;

            if (isLoggedIn && hasRequiredPerms) {
                if (
                    !OfflineManager.getInstance().isPhoneOffline() && // Privacy policy page opening delayed until user login online
                    auth.activeUser.currentPolicy?.[0] &&
                    auth.activeUser.acceptedVersionPP?.toString() !==
                        auth.activeUser.currentPolicy[0].majorVersion.toString() &&
                    props.location.pathname !== '/policy' &&
                    !auth?.switchingUser // Don't redirect if switch user modal is opened, we will open policy inside modal over there
                ) {
                    return <Redirect to={{ pathname: '/policy', state: { target: props.location.pathname } }} />;
                }

                const studySiteReady = auth?.activeStudySite?.activeStudySiteReady;
                const studyId = auth?.activeStudySite.activeStudy?.studyId;
                const siteId = auth?.activeStudySite.activeSite?.siteId;
                if (
                    (!!accessRoute.studySiteRequired && (!studySiteReady || !studyId || !siteId)) ||
                    (accessRoute.studyOnlyRequired && (!studySiteReady || !studyId))
                ) {
                    if (!!accessRoute.canBeLandingPage && getCookie(OrionCookies.oktaHandshake)) {
                        return <AccessibleRoute component={component} {...props} />;
                    }

                    return (
                        <Redirect
                            to={{
                                pathname: '/activestudysite',
                                state: { target: props.location.pathname },
                            }}
                        />
                    );
                }
                return <AccessibleRoute component={component} {...props} />;
            }

            const state = props.location;
            let path = permsRedirect;
            if (!isLoggedIn) {
                path = getCookie(OrionCookies.oktaHandshake) ? '/sso/okta/login' : '/login';
            }

            if (isLoggedIn && auth.activeUser.userType === UserTypes.Patient) {
                return (
                    <Redirect
                        to={{
                            pathname: '/patientAccessDenied',
                            state: { target: props.location.pathname },
                        }}
                    />
                );
            }
            if (isLoggedIn && roleId === RoleVariantEnum.Librarian) {
                return (
                    <Redirect
                        to={{
                            pathname: '/activitylibrary',
                            state: { target: props.location.pathname },
                        }}
                    />
                );
            }
            if (isLoggedIn && roleId === RoleVariantEnum.StudyBuilder) {
                return (
                    <Redirect
                        to={{
                            pathname: '/study',
                            state: { target: props.location.pathname },
                        }}
                    />
                );
            }

            return <Redirect to={{ pathname: path, state: { target: state.pathname } }} />;
        },
        [auth]
    );

    return <Route {...rest} render={(props): ReactElement => getComponentOrRedirect(props, accessRoute)} />;
};
