import { Attendees, Flex, Navbar, NavbarHeader, NavbarItem } from 'amazon-chime-sdk-component-library-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '../../providers/NavigationProvider';

const Navigation: FC = () => {
    const { t } = useTranslation('global');
    const { toggleRoster, closeNavbar } = useNavigation();

    return (
        <Navbar className="nav" flexDirection="column" container>
            <NavbarHeader title="Navigation" onClose={closeNavbar} />
            <Flex css="margin-top: 0rem;">
                <NavbarItem icon={<Attendees />} onClick={toggleRoster} label={t('telehealth.controls.attendees')} />
            </Flex>
        </Navbar>
    );
};

export default Navigation;
