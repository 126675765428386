import { DialogParts } from 'common/routing/blocking/ci-non-route-block';

const blockLogoutSessionKey = 'BLOCK_LOGOUT';
const blockLogoutDialogPartsSessionKey = 'BLOCK_LOGOUT_DIALOG_PARTS';

export const setBlockLogout = (blockLogout: boolean): void => {
    window.sessionStorage.setItem(blockLogoutSessionKey, blockLogout ? 'true' : '');
};

export const shouldBlockLogout = (): boolean => {
    return !!window.sessionStorage.getItem(blockLogoutSessionKey);
};

export const setLogoutDialogParts = (dialogParts: DialogParts): void => {
    window.sessionStorage.setItem(blockLogoutDialogPartsSessionKey, JSON.stringify(dialogParts || {}));
};

export const getLogoutDialogParts = (): DialogParts => {
    return JSON.parse(window.sessionStorage.getItem(blockLogoutDialogPartsSessionKey));
};
