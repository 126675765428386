import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

export const routes = [
    {
        component: lazy(() => import('module/patientIdRange/PatientIDRangeManagementPage')),
        permissions: ['Study_Read', 'Study_Create', 'Study_Edit', 'Study_Delete'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/:studyId',
    },
    {
        component: lazy(() => import('module/patientIdRange/EditRangeDashboardPage')),
        permissions: ['PatientIdRange_Read', 'PatientIdRange_Edit', 'PatientIdRange_Delete', 'PatientIdRange_Create'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/:studyId/:pk/:sk/:idName?',
    },
];
