import { useLogger } from 'amazon-chime-sdk-component-library-react';
import { VideoPriorityBasedPolicy } from 'amazon-chime-sdk-js';
import { JoinMeetingInfo, Layout, MeetingMode, VideoFiltersCpuUtilization } from 'common/telehealth/types';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';

type Props = {
    close: () => void;
    children: ReactNode;
};

interface AppStateValue {
    meetingId: string;
    localUserName: string;
    region: string;
    isWebAudioEnabled: boolean;
    videoTransformCpuUtilization: string;
    imageBlob: Blob | undefined;
    meetingMode: MeetingMode;
    step: string;
    isAdmin: boolean;
    enableSimulcast: boolean;
    priorityBasedPolicy: VideoPriorityBasedPolicy | undefined;
    keepLastFrameWhenPaused: boolean;
    layout: Layout;
    joinInfo: JoinMeetingInfo | undefined;
    setStep: (step: string) => void;
    close: () => void;
    toggleWebAudio: () => void;
    toggleSimulcast: () => void;
    togglePriorityBasedPolicy: () => void;
    toggleKeepLastFrameWhenPaused: () => void;
    setCpuUtilization: (videoTransformCpuUtilization: string) => void;
    setMeetingMode: Dispatch<SetStateAction<MeetingMode>>;
    setJoinInfo: (joinInfo: JoinMeetingInfo | undefined) => void;
    setLayout: Dispatch<SetStateAction<Layout>>;
    setMeetingId: Dispatch<SetStateAction<string>>;
    setLocalUserName: Dispatch<SetStateAction<string>>;
    setRegion: Dispatch<SetStateAction<string>>;
    setBlob: (imageBlob: Blob) => void;
    setIsAdmin: (active: boolean) => void;
}

const AppStateContext = createContext<AppStateValue | null>(null);

export function useAppState(): AppStateValue {
    const state = useContext(AppStateContext);

    if (!state) {
        throw new Error('useAppState must be used within AppStateProvider');
    }

    return state;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function AppStateProvider({ children, close }: Props) {
    const logger = useLogger();

    const [meetingId, setMeetingId] = useState('');
    const [region, setRegion] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [meetingMode, setMeetingMode] = useState(MeetingMode.Attendee);
    const [joinInfo, setJoinInfo] = useState<JoinMeetingInfo | undefined>(undefined);
    const [layout, setLayout] = useState(Layout.Gallery);
    const [localUserName, setLocalUserName] = useState('');
    const [isWebAudioEnabled, setIsWebAudioEnabled] = useState(true);
    const [priorityBasedPolicy, setPriorityBasedPolicy] = useState<VideoPriorityBasedPolicy | undefined>(undefined);
    const [enableSimulcast, setEnableSimulcast] = useState(false);
    const [keepLastFrameWhenPaused, setKeepLastFrameWhenPaused] = useState(false);
    const [step, setStep] = useState('home');
    const [videoTransformCpuUtilization, setCpuPercentage] = useState(VideoFiltersCpuUtilization.CPU40Percent);
    const [imageBlob, setImageBlob] = useState<Blob | undefined>(undefined);

    useEffect(() => {
        /* Load a canvas that will be used as the replacement image for Background Replacement */
        async function loadImage() {
            const canvas = document.createElement('canvas');
            canvas.width = 500;
            canvas.height = 500;
            const ctx = canvas.getContext('2d');
            if (ctx !== null) {
                const grd = ctx.createLinearGradient(0, 0, 250, 0);
                grd.addColorStop(0, '#000428');
                grd.addColorStop(1, '#004e92');
                ctx.fillStyle = grd;
                ctx.fillRect(0, 0, 500, 500);
                canvas.toBlob(function (blob) {
                    if (blob !== null) {
                        setImageBlob(blob);
                    }
                });
            }
        }
        loadImage();
    }, []);

    const toggleWebAudio = (): void => {
        setIsWebAudioEnabled((current) => !current);
    };

    const toggleSimulcast = (): void => {
        setEnableSimulcast((current) => !current);
    };

    const togglePriorityBasedPolicy = (): void => {
        if (priorityBasedPolicy) {
            setPriorityBasedPolicy(undefined);
        } else {
            setPriorityBasedPolicy(new VideoPriorityBasedPolicy(logger));
        }
    };

    const toggleKeepLastFrameWhenPaused = (): void => {
        setKeepLastFrameWhenPaused((current) => !current);
    };

    const setCpuUtilization = (filterValue: string): void => {
        setCpuPercentage(filterValue);
    };

    const setBlob = (imageBlob: Blob): void => {
        setImageBlob(imageBlob);
    };

    const providerValue = {
        meetingId,
        localUserName,
        isWebAudioEnabled,
        videoTransformCpuUtilization,
        imageBlob,
        region,
        meetingMode,
        layout,
        joinInfo,
        enableSimulcast,
        priorityBasedPolicy,
        keepLastFrameWhenPaused,
        toggleWebAudio,
        togglePriorityBasedPolicy,
        toggleKeepLastFrameWhenPaused,
        toggleSimulcast,
        setCpuUtilization,
        setMeetingMode,
        setLayout,
        setJoinInfo,
        setMeetingId,
        setLocalUserName,
        setRegion,
        setBlob,
        step,
        setStep,
        isAdmin,
        setIsAdmin,
        close,
    };

    return <AppStateContext.Provider value={providerValue}>{children}</AppStateContext.Provider>;
}
