import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

const UserProfilePage = lazy(() => import('module/user/profile/user_profile.page'));

export let routes = [
    {
        component: lazy(() => import('module/user/UsersPage')),
        permissions: ['User_Read', 'Subscriber_User_Create', 'User_Edit', 'User_Delete'],
        role: [
            BaseRoles.UserId,
            RoleVariantEnum.StudySupport,
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SystemAdminstrator,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/user/:tab',
    },
    {
        component: lazy(() => import('module/user/activation/user_activation.page')),
        permissions: [],
        role: [],
        path: '/activateUser',
    },
    {
        component: lazy(() => import('module/user/UserLandingPage')),
        permissions: ['Subscriber_User_Create', 'User_Edit', 'User_Read', 'User_Delete'],
        role: [
            BaseRoles.UserId,
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/user/:userId/details/:tab?',
    },
    {
        component: UserProfilePage,
        permissions: ['User_Read', 'User_Edit', 'User_Delete'],
        role: [
            BaseRoles.UserId,
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.TranslationRepresentative,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.SystemAdminstrator,
            RoleVariantEnum.ClinicalTrialLead,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.BlindedStudyManager,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/userprofile',
    },
    {
        component: UserProfilePage,
        permissions: ['User_Read', 'User_Edit', 'User_Delete'],
        role: [
            BaseRoles.UserId,
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.TranslationRepresentative,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.SystemAdminstrator,
            RoleVariantEnum.ClinicalTrialLead,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.BlindedStudyManager,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/userprofile/:view',
    },
];
