/* eslint-disable no-console */
import { dateToUTCString, formatDate } from '@orion/fe-utils';

interface WebAppDetails {
    appVersion: string;
    gitVersion: string;
    branch: string;
    tag: string;
    buildDate: string;
}

export default function getWebAppDetails(): WebAppDetails {
    const buildDate = formatDate(
        'MM/DD/YYYY HH:mm:ss',
        dateToUTCString(process.env.REACT_APP_GIT_COMMIT_DATE || undefined)
    );

    return {
        appVersion: process.env.REACT_APP_LUNEXIS_VERSION,
        gitVersion: process.env.REACT_APP_GIT_COMMIT_HASH || '',
        branch: process.env.REACT_APP_GIT_BRANCH_NAME || '',
        tag: 'N/A',
        buildDate,
    };
}

export const formattedConsoleLog = (key: string, value: string): void =>
    console.log(`%c${key} %c: ${value}`, 'font-weight:bold;color:red;', 'font-weight:normal;');

export const logWebAppDetails = (): void => {
    const { appVersion, gitVersion, branch, tag, buildDate } = getWebAppDetails();
    console.group('Lunexis Web App Details');
    formattedConsoleLog('App version', appVersion);
    formattedConsoleLog('Git version', gitVersion);
    if (!!branch) {
        formattedConsoleLog('Branch', branch);
    }
    formattedConsoleLog('Tag', tag);
    formattedConsoleLog('Build date', buildDate);
    console.groupEnd();
};
