import { invokeMap, map } from 'lodash';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

// Based on jedwards1211's multibackend creator:
//    https://github.com/jcoreio/react-dnd-multi-backend/blob/master/src/MultiBackend.js
const createMultiBackend = (factory: any[]): any => {
    return (manager) => {
        const backends = map(factory, (createBackend) => createBackend(manager));

        return {
            setup: (): void => {
                invokeMap(backends, 'setup');
            },
            teardown: (): void => {
                invokeMap(backends, 'teardown');
            },
            connectDragSource: (sourceId, node, options): (() => void) => {
                const disconnects = invokeMap(backends, 'connectDragSource', sourceId, node, options);
                return (): void => {
                    for (const disconnect of disconnects || []) {
                        disconnect();
                    }
                };
            },
            connectDragPreview: (sourceId, node, options): (() => void) => {
                const disconnects = invokeMap(backends, 'connectDragPreview', sourceId, node, options);
                return (): void => {
                    for (const disconnect of disconnects || []) {
                        disconnect();
                    }
                };
            },
            connectDropTarget: (targetId, node): (() => void) => {
                const disconnects = invokeMap(backends, 'connectDropTarget', targetId, node);
                return (): void => {
                    for (const disconnect of disconnects || []) {
                        disconnect();
                    }
                };
            },
        };
    };
};

export const Html5AndTouchBackend = createMultiBackend([TouchBackend, HTML5Backend]);
