import { split, startsWith, trimStart } from 'lodash';

export enum OrionCookies {
    oktaLogout = 'okta-logout',
    oktaIssuer = 'okta-issuer',
    oktaClientId = 'okta-client-id',
    oktaState = 'okta-state',
    oktaHandshake = 'okta-handshake',
    oktaCodeVerifier = 'okta-code-verifier',
}

export const setCookie = (name: string, value: string, expirationMins: number): void => {
    const expiresDate = new Date();
    expiresDate.setTime(expiresDate.getTime() + expirationMins * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${expiresDate.toUTCString()}; path=/`;
};

export const getCookie = (name: string): string => {
    const _name = `${name}=`;
    const cookies = split(document.cookie, ';');
    for (const _cookie of cookies) {
        const cookie = trimStart(_cookie);
        if (startsWith(cookie, _name)) {
            return cookie.substring(name.length + 1);
        }
    }
    return '';
};

export const deleteCookie = (name: string): void => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
