import {
    Camera,
    Cog,
    ControlBarButton,
    DeviceLabels,
    Dots,
    Sound,
    useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { useTranslation } from 'react-i18next';

const DevicePermissionControl = (props: { deviceLabels: DeviceLabels }) => {
    const { t } = useTranslation('global');
    const meetingManager = useMeetingManager();

    const handleClick = async () => {
        await meetingManager.invokeDeviceProvider(props.deviceLabels);
    };

    const label =
        props.deviceLabels === DeviceLabels.AudioAndVideo
            ? t('telehealth.controls.device')
            : props.deviceLabels === DeviceLabels.Audio
            ? t('telehealth.controls.audio')
            : props.deviceLabels === DeviceLabels.Video
            ? t('telehealth.controls.video')
            : t('telehealth.controls.none');

    const icon =
        props.deviceLabels === DeviceLabels.AudioAndVideo ? (
            <Cog />
        ) : props.deviceLabels === DeviceLabels.Audio ? (
            <Sound />
        ) : props.deviceLabels === DeviceLabels.Video ? (
            <Camera />
        ) : (
            <Dots />
        );

    return (
        props.deviceLabels !== DeviceLabels.None && <ControlBarButton icon={icon} onClick={handleClick} label={label} />
    );
};

export default DevicePermissionControl;
