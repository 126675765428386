import { gql } from '@apollo/client';
import { Typography } from '@mui/material';
import { DeviceLabels, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import {
    AttendeeResponse,
    MeetingManagerJoinOptions,
} from 'amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { gqlSimpleQueryAsPromise } from 'common/graphql/utils';
import config from 'common/telehealth/config';
import MeetingRoom from 'common/telehealth/containers/Meeting';
import { JoinInfo, JoinMeetingInfo } from 'common/telehealth/types';
import { Form, maxLength, required } from 'common/utils/validations';
import { CIPrimaryButton } from 'common/viewcomp/forms/buttons';
import { CITextField } from 'common/viewcomp/forms/fields/text';
import { replace, trim } from 'lodash';
import { FormEvent, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../providers/AppStateProvider';
import { getErrorContext } from '../providers/ErrorProvider';

function MeetingForm(): ReactElement {
    const { t } = useTranslation('global');
    const meetingManager = useMeetingManager();

    const {
        meetingId,
        enableSimulcast,
        priorityBasedPolicy,
        keepLastFrameWhenPaused,
        isWebAudioEnabled,
        setJoinInfo,
        localUserName,
        step,
        setStep,
        close,
        isAdmin,
    } = useAppState();

    const { errorMessage, updateErrorMessage } = useContext(getErrorContext());

    async function getAttendee(meetingId: string, attendeeId: string): Promise<AttendeeResponse> {
        try {
            const response = await gqlSimpleQueryAsPromise<{ Attendee: { name: string } }>(
                gql`
                    query getTelehealthMeetingAttendee($input: InputGetTelehealthMeetingAttendee) {
                        getTelehealthMeetingAttendee(input: $input) {
                            Attendee {
                                name
                            }
                        }
                    }
                `,
                {
                    queryString: 'getTelehealthMeetingAttendee',
                    variables: {
                        input: {
                            meetingId,
                            attendeeId,
                        },
                    },
                }
            );

            return {
                name: response.Attendee.name,
            };
        } catch (error) {
            return {};
        }
    }

    const createGetAttendeeCallback =
        (meetingId: string) =>
        (chimeAttendeeId: string): Promise<AttendeeResponse> =>
            getAttendee(meetingId, chimeAttendeeId);

    async function handleJoinMeeting(values: any) {
        updateErrorMessage('');
        const pk = trim(meetingId);
        const name = trim(values.name);

        meetingManager.getAttendee = createGetAttendeeCallback(pk);

        try {
            const response = await gqlSimpleQueryAsPromise<JoinInfo>(
                gql`
                    query joinTelehealthMeeting($input: InputJoinTelehealthMeeting) {
                        joinTelehealthMeeting(input: $input) {
                            JoinInfo {
                                Title
                                Meeting {
                                    MeetingId
                                    ExternalMeetingId
                                    MediaPlacement {
                                        AudioHostUrl
                                        AudioFallbackUrl
                                        ScreenDataUrl
                                        ScreenSharingUrl
                                        ScreenViewingUrl
                                        SignalingUrl
                                        TurnControlUrl
                                        EventIngestionUrl
                                    }
                                    MediaRegion
                                }
                                Attendee {
                                    ExternalUserId
                                    AttendeeId
                                    JoinToken
                                }
                            }
                        }
                    }
                `,
                {
                    queryString: 'joinTelehealthMeeting',
                    variables: {
                        input: {
                            pk,
                            name,
                        },
                    },
                }
            );

            const { JoinInfo } = response as unknown as Record<string, JoinMeetingInfo>;
            setJoinInfo(JoinInfo);

            const meetingSessionConfiguration = new MeetingSessionConfiguration(JoinInfo?.Meeting, JoinInfo?.Attendee);
            if (
                config.postLogger &&
                meetingSessionConfiguration.meetingId &&
                meetingSessionConfiguration.credentials &&
                meetingSessionConfiguration.credentials.attendeeId
            ) {
                const existingMetadata = config.postLogger.metadata;
                config.postLogger.metadata = {
                    ...existingMetadata,
                    meetingId: meetingSessionConfiguration.meetingId,
                    attendeeId: meetingSessionConfiguration.credentials.attendeeId,
                };
            }

            meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = enableSimulcast;
            if (priorityBasedPolicy) {
                meetingSessionConfiguration.videoDownlinkBandwidthPolicy = priorityBasedPolicy;
            }
            meetingSessionConfiguration.keepLastFrameWhenPaused = keepLastFrameWhenPaused;
            const options: MeetingManagerJoinOptions = {
                deviceLabels: DeviceLabels.None,
                enableWebAudio: isWebAudioEnabled,
            };

            await meetingManager.join(meetingSessionConfiguration, options);
            await meetingManager.start();
            setStep('meeting');
        } catch (error) {
            updateErrorMessage((error as Error).message);
        }
    }

    if (step === 'meeting') {
        return <MeetingRoom />;
    }

    const initialValues = {
        name: localUserName,
    } as Partial<FormEvent<Element>>;

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleJoinMeeting}
            render={({ handleSubmit, submitting }): ReactElement => {
                return (
                    <form id="meeting-start" onSubmit={handleSubmit} noValidate>
                        <Typography variant="h4" component="h1">
                            {isAdmin ? t('telehealth.title') : t('telehealth.join')}
                        </Typography>
                        <p className="meeting-info">
                            {t('telehealth.id', { id: replace(trim(meetingId), 'TelehealthMeeting.', '') })}
                        </p>

                        {errorMessage && (
                            <div className="error">
                                <Typography component="h2">{t('telehealth.error.title')}</Typography>
                                <p>{t('telehealth.error.description')}</p>
                            </div>
                        )}

                        {isAdmin ? (
                            <CITextField
                                t={t}
                                id="name"
                                name="name"
                                labelI18NKey="telehealth.form.name"
                                required
                                validations={[required(), maxLength(100)]}
                                maxLength={101}
                                ariaLabel={t('telehealth.form.name')}
                            />
                        ) : (
                            <p>{localUserName}</p>
                        )}
                        <div className="actions">
                            <CIPrimaryButton
                                id="join-meeting"
                                isLoading={submitting}
                                disabled={submitting}
                                type="submit"
                            >
                                {t('telehealth.continue')}
                            </CIPrimaryButton>
                            <CIPrimaryButton id="close-meeting" disabled={submitting} onClick={close}>
                                {t('telehealth.close')}
                            </CIPrimaryButton>
                        </div>
                    </form>
                );
            }}
        />
    );
}

export default MeetingForm;
