import { registerPlugin } from '@capacitor/core';
import {
    AuthPluginDef,
    DeviceInitializerPluginDef,
    IdleTimerPluginDef,
    LogPluginDef,
    OfflineSyncPluginDef,
    QueryResolverPluginDef,
    RSAPluginDef,
} from './definitions';

export const DeviceInitializerPlugin = registerPlugin<DeviceInitializerPluginDef>('DeviceInitializerPlugin');
export const AuthPlugin = registerPlugin<AuthPluginDef>('AuthPlugin');
export const IdleTimerPlugin = registerPlugin<IdleTimerPluginDef>('IdleTimerPlugin');
export const RSAPlugin = registerPlugin<RSAPluginDef>('RSAPlugin');
export const QueryResolverPlugin = registerPlugin<QueryResolverPluginDef>('QueryResolverPlugin');
export const OfflineSyncPlugin = registerPlugin<OfflineSyncPluginDef>('OfflineSyncPlugin');
export const LogPlugin = registerPlugin<LogPluginDef>('LogPlugin');
