import { AuthUser } from 'common/auth/auth_context';
import { CIError } from 'common/error/error_util';
import { Device, UserStudies } from 'common/graphql/types';
import { forEach, intersectionBy } from 'lodash';
import { DatabaseManager } from './native.database.manager';
import { NativeErrorCode } from './native.errorcode';

export class NativeAuthManager {
    private static __instance: NativeAuthManager = new NativeAuthManager();

    private constructor() {}

    public static getInstance(): NativeAuthManager {
        return NativeAuthManager.__instance;
    }

    public getAuthUser(username: string, password: string): Promise<AuthUser> {
        return DatabaseManager.getInstance()
            .getAuthUser(username, password)
            .then((data) => JSON.parse(data.authUser))
            .then((authUser: AuthUser) => authUser)
            .catch((error) => {
                throw CIError.fromNativeError({
                    code: error.code,
                    message: error.message,
                });
            });
    }

    public async storeAuthUser(
        username: string,
        password: string,
        authUser: AuthUser,
        device: Device
    ): Promise<AuthUser> {
        // filter study site of user as per provisioned device
        this.updateStudySiteAsPerDevice(authUser, device);

        return DatabaseManager.getInstance()
            .storeAuthUser(username, password, authUser)
            .then((authUser: AuthUser) => authUser)
            .catch((error) => {
                throw CIError.fromNativeError({
                    code: error.code,
                    message: error.message,
                });
            });
    }

    private updateStudySiteAsPerDevice(authUser: AuthUser, device: Device): AuthUser {
        let allowedStudies: UserStudies[] = [];
        if (device.listOfStudies && device.listOfStudies.length > 0) {
            allowedStudies = intersectionBy(authUser.userStudies, device.listOfStudies, 'studyId');
        } else {
            allowedStudies = authUser.userStudies;
        }

        if (allowedStudies.length === 0) {
            throw CIError.fromNativeError({
                code: NativeErrorCode.auth.NO_STUDY_ACCESS,
                message: 'user does not have access to the device’s study',
            });
        }

        let result: UserStudies[] = [];
        if (device.listOfSites && device.listOfSites.length > 0) {
            forEach(allowedStudies, (study) => {
                const allowedSites = intersectionBy(study.userStudySites, device.listOfSites, 'siteId');
                if (allowedSites.length > 0) {
                    study.userStudySites = allowedSites;
                    result.push(study);
                }
            });
        } else {
            result = allowedStudies;
        }

        authUser.userStudies = result;
        return authUser;
    }
}
