import { jwtDecode } from 'jwt-decode';

import { getAccessTokensFromRefreshToken } from './okta.utils';
import { SsoAccessTokenData, SsoIdTokenData, SsoSessionKeys } from './sso.types';

export class OktaSession {
    private storage: Storage;
    private oktaSessionKeys: SsoSessionKeys;

    private _issuer: string = '';
    get issuer(): string {
        return this._issuer;
    }

    private _clientId: string = '';
    get clientId(): string {
        return this._clientId;
    }

    private _userId: string = '';
    get userId(): string {
        return this._userId;
    }

    private _userPooleKey = '';
    get userPoolKey(): string {
        if (!this._userPooleKey) {
            throw new Error('no key');
        }
        return this._userPooleKey;
    }

    private _idTokenKey: string = '';
    private _accessTokenKey: string = '';
    private _refreshTokenKey: string = '';

    constructor(storage: Storage, oktaSessionKeys: SsoSessionKeys) {
        this.storage = storage;
        this.oktaSessionKeys = oktaSessionKeys;

        const decodedIdToken = jwtDecode<SsoIdTokenData>(this.oktaSessionKeys.idToken);
        const decodedAccessToken = jwtDecode<SsoAccessTokenData>(this.oktaSessionKeys.accessToken);

        this._issuer = decodedIdToken.iss || decodedAccessToken.iss;
        this._clientId = decodedIdToken.aud;
        this._userId = decodedIdToken.sub || decodedAccessToken.sub;

        this._userPooleKey = `OktaSession.${this.issuer}.${this._clientId}.${this._userId}`;
        this._idTokenKey = `${this._userPooleKey}.idToken`;
        this._accessTokenKey = `${this._userPooleKey}.accessToken`;
        this._refreshTokenKey = `${this._userPooleKey}.refreshToken`;

        this.replaceKeys(oktaSessionKeys);
    }

    private replaceKeys = (oktaSessionKeys: SsoSessionKeys): void => {
        this.storage.setItem(this._idTokenKey, oktaSessionKeys.idToken);
        this.storage.setItem(this._accessTokenKey, oktaSessionKeys.accessToken);
        this.storage.setItem(this._refreshTokenKey, oktaSessionKeys.refreshToken);
    };

    public getIdToken = async (): Promise<string> => {
        const idToken = this.storage.getItem(this._idTokenKey);
        const refreshToken = this.storage.getItem(this._refreshTokenKey);

        if (!refreshToken) {
            return idToken;
        }

        const decodedIdToken = jwtDecode<SsoIdTokenData>(idToken);
        if (decodedIdToken.exp <= Date.now() / 1000) {
            const newSessionKeys = await getAccessTokensFromRefreshToken(refreshToken);
            this.replaceKeys(newSessionKeys);
            return newSessionKeys.idToken;
        }
        return idToken;
    };

    public invalidate = (): void => {
        const idTokenKey = `${this._userPooleKey}.idToken`;
        const accessTokenKey = `${this._userPooleKey}.accessToken`;
        const refreshTokenKey = `${this._userPooleKey}.refreshToken`;

        this.storage.removeItem(idTokenKey);
        this.storage.removeItem(accessTokenKey);
        this.storage.removeItem(refreshTokenKey);
    };
}
