import { ConsoleLogger, MultiLogger, POSTLogger } from 'amazon-chime-sdk-js';
import { includes, replace } from 'lodash';
import { MeetingConfig, SDK_LOG_LEVELS } from './types';

const urlParams = new URLSearchParams(window.location.search);
const queryLogLevel = urlParams.get('logLevel') || 'info';
const logLevel = SDK_LOG_LEVELS[queryLogLevel] || SDK_LOG_LEVELS.info;

const meetingConfig: MeetingConfig = {
    simulcastEnabled: false,
    logger: new ConsoleLogger('ChimeClinicalInk', logLevel),
};

const BASE_URL: string = [
    window.location.protocol,
    '//',
    window.location.host,
    replace(window.location.pathname, /\/*$/, '/'),
].join('');

if (!includes(['0.0.0.0', '127.0.0.1', 'localhost'], window.location.hostname)) {
    const postLogger = new POSTLogger({
        url: `${BASE_URL}logs`,
        logLevel,
        metadata: {
            appName: 'ChimeComponentLibraryReactDemo',
            timestamp: Date.now().toString(), // Add current timestamp for unique AWS CloudWatch log stream generation. This will be unique per POSTLogger creation in time.
        },
    });
    meetingConfig.logger = new MultiLogger(meetingConfig.logger, postLogger);
    meetingConfig.postLogger = postLogger;
}

export default meetingConfig;
