import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/browser';
import * as SentryTracing from '@sentry/tracing';
import getWebAppDetails from 'common/utils/web_app_details';
import { replace } from 'lodash';

const dsn = Capacitor.isNativePlatform()
    ? process.env.NATIVE_APP_SENTRY_DSN
    : 'https://238417d64eda42eba7b8e52a4292e8ae@o255194.ingest.sentry.io/5465309';

Sentry.init({
    dsn,
    integrations: [
        new SentryTracing.Integrations.BrowserTracing({
            idleTimeout: 5000,
            beforeNavigate: (context) => {
                const numberregex = /reports\/[a-z0-9]{24}/g; // for report specific URL
                const path = numberregex.test(window.location.pathname)
                    ? replace(window.location.pathname, numberregex, 'reports/reportId')
                    : replace(
                          window.location.pathname,
                          /.[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g,
                          'Id'
                      );
                return {
                    ...context,
                    name: path,
                };
            },
        }),
    ],
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: getWebAppDetails().appVersion,
    tracesSampleRate: 1.0,
});

//get data from the session object when the page is reloaded
const activeUser = JSON.parse(localStorage.getItem('activeUser'));
if (activeUser) {
    Sentry.setUser({ id: activeUser?.pk, username: activeUser?.username }); //adding user pk to the id if active user is present
}

Sentry.setTag('gitHash', getWebAppDetails().gitVersion);

export default Sentry;
