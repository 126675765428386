import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

export let routes = [
    {
        component: lazy(() => import('module/studyreports/studyreports.page')),
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.ClinicalTrialLead,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.BlindedStudyManager,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/studyreports/:studyId/:reportInfo/:dashboardId?',
    },
];
