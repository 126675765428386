import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
import { protocolDesignerSubTabParam } from './utils/routes';

export let routes = [
    {
        component: lazy(() => import('module/protocoldesigner/protocol_designer.page')),
        permissions: ['Study_Create', 'Study_Edit', 'Study_Delete', 'Study_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.AdminReadOnly,
        ],
    },
    {
        component: lazy(() => import('module/protocoldesigner/protocol_designer.page')),
        permissions: ['Study_Create', 'Study_Edit', 'Study_Delete', 'Study_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: `/study/:studyId/:studyDetails?/:${protocolDesignerSubTabParam}?`,
    },
];
