import { RouteType } from 'common/types/route';
import { preloadLazyComponent } from 'common/utils/loading';
import { PATIENT_LISTING_ROUTE, SITE_LISTING_ROUTE } from 'common/utils/sponsorUtils';

export const routes: RouteType[] = [
    {
        ...PATIENT_LISTING_ROUTE,
        component: preloadLazyComponent(() => import('module/sponsordashboard/PatientListingPage')),
    },
    {
        ...SITE_LISTING_ROUTE,
        component: preloadLazyComponent(() => import('module/sponsordashboard/SiteListingPage')),
    },
];
