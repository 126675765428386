import { ControlBarButton } from 'amazon-chime-sdk-component-library-react';
import { AuthActions } from 'common/auth/auth_context';
import Thumbnail from 'common/telehealth/components/icons/Thumbnail';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const ThumbnailControl: FC = () => {
    const { t } = useTranslation('global');
    const dispatch = useDispatch();

    return (
        <>
            <ControlBarButton
                icon={<Thumbnail />}
                onClick={() => {
                    dispatch(AuthActions.updateTelevisitThumbnailMode());
                }}
                label={t('telehealth.controls.thumbnail')}
            />
        </>
    );
};

export default ThumbnailControl;
