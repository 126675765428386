import { Capacitor } from '@capacitor/core';
import { AuthUser } from 'common/auth/auth_context';
import { AuthPlugin } from './ci-native-plugins/CIPlugins';

export class DatabaseManager {
    private static __instance: DatabaseManager;

    private constructor() {}

    public static getInstance(): DatabaseManager {
        if (!DatabaseManager.__instance) {
            DatabaseManager.__instance = new DatabaseManager();
        }

        return DatabaseManager.__instance;
    }

    async storeAuthUser(username: string, password: string, authUser: AuthUser): Promise<AuthUser> {
        if (Capacitor.isNativePlatform()) {
            await AuthPlugin.saveAuthData({ username, password, authUser: JSON.stringify(authUser) });
        }
        return authUser;
    }

    getAuthUser(username: string, password: string): Promise<{ authUser: string }> {
        return AuthPlugin.getAuthData({ username, password });
    }
}
