import { OfflineManager } from 'common/native-app-support/native.offline.manager';
import { produce } from 'immer';
import { Action } from 'redux-logic';

export enum NetworkStatus {
    UNKNOWN = 'UNKNOWN',
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
}

export interface NetworkState {
    isOffline: boolean;
    status: NetworkStatus;
}

function getDefaultState(): NetworkState {
    let defaultState: NetworkState = {
        isOffline: false,
        status: NetworkStatus.UNKNOWN,
    };

    const isOffline = OfflineManager.getInstance().isPhoneOffline();
    defaultState.isOffline = isOffline;
    defaultState.status = isOffline ? NetworkStatus.OFFLINE : NetworkStatus.ONLINE;

    return defaultState;
}

export enum NetworkActionType {
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE',
}

export const NetworkActions = {
    setOffline: (): Action<string, undefined, undefined> => ({
        type: NetworkActionType.OFFLINE,
    }),
    setOnline: (): Action<string, undefined, undefined> => ({
        type: NetworkActionType.ONLINE,
    }),
};

export const networkReducer = (state = getDefaultState(), action): NetworkState => {
    switch (action.type) {
        case NetworkActionType.OFFLINE:
            return produce(state, (newState) => {
                newState.isOffline = true;
                newState.status = NetworkStatus.OFFLINE;
                return newState;
            });
        case NetworkActionType.ONLINE:
            return produce(state, (newState) => {
                newState.isOffline = false;
                newState.status = NetworkStatus.ONLINE;
                return newState;
            });

        default:
            return state;
    }
};
