import styled from 'styled-components';

interface Props {
    showNav: boolean;
    showRoster: boolean;
}

export const StyledLayout = styled.main<Props>`
    height: 100vh;
    width: 100%;
    display: grid;

    .video-content {
        grid-area: content;
    }

    // Hack: this is caused by the use of Emotion superseeding styled-components
    // We need to remove styled-components in favor of Emotion in the future
    [data-testid='video-tile'][css] {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        width: 20vw;
        max-height: 30vh;
        height: auto;

        video {
            position: static;
        }
    }

    .ch-icon {
        background: transparent;
    }

    .ch-control-bar-item-caret .ch-icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    [data-testid='control-bar-item'][popover] {
        position: static;
        background: transparent;
        overflow: visible;
    }

    ${({ showNav, showRoster }) => {
        if (showNav && showRoster) {
            return `
        grid-template-columns: auto auto 1fr;
        grid-template-areas: 'nav roster content';
      `;
        }

        if (showNav) {
            return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'nav content';
      `;
        }

        if (showRoster) {
            return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'roster content';
      `;
        }

        return `
      grid-template-columns: 1fr;
      grid-template-areas: 'content';
    `;
    }}

    .nav {
        grid-area: nav;
    }

    .roster {
        grid-area: roster;
        z-index: 2;
    }

    @media screen and (min-width: 769px) {
        .mobile-toggle {
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas: 'content';

        .nav {
            grid-area: unset;
            position: fixed;
        }

        .roster {
            grid-area: unset;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 320px;
        }
    }

    @media screen and (max-width: 460px) {
        .roster {
            max-width: 100%;
        }
    }
`;

export const StyledContent = styled.div`
    position: relative;
    grid-area: content;
    height: 100%;
    display: flex;
    flex-direction: column;

    .videos {
        flex: 1;

        > [data-testid='video-tile']:not(:first-of-type) {
            max-width: 300px;
            width: 15vw;
        }

        video {
            background: transparent;
        }
    }

    .controls {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
    }

    @media screen and (max-width: 768px) {
        .controls {
            position: static;
            transform: unset;
        }
    }
`;
